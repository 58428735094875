import React, {useState, useEffect} from 'react'
import {Col, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import Moment from 'react-moment'
import * as Yup from 'yup'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'
import {Field, Formik, Form} from 'formik'
import UserSelector from '../../../common/components/selectors/UserSelector'
import {TenantTypeIds} from '../../../common/constants/tenantTypeIds'
import {WorkOrderClient} from '../../../infrastracture/api/WorkOrderClient'
import _ from 'lodash'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {ChargesClient} from '../../../infrastracture/api/ChargesClient'
import {TableHeader} from '../../../common/components/TableHeader'
import {toMoneyFormat} from '../../../common/helpers/stringHelper'

interface Props {
  handleClose: () => void
  show: boolean
  parentWorkOrderId: any
  refreshData: boolean
  updateParentProjectCost?: (sum: any) => void
}

const WorkOrderProjectCostModal: React.FC<Props> = (props) => {
  const {handleClose, show, parentWorkOrderId, updateParentProjectCost, refreshData} = props

  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState<any[]>([])
  const [projectCost, setProjectCost] = useState(0)

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  useEffect(() => {
    if (parentWorkOrderId) fetchData()
  }, [parentWorkOrderId, refreshData])

  const fetchData = async () => {
    var response = await ChargesClient.GetChargesForProjectWorkOrder(parentWorkOrderId)

    const {data} = response
    setItems(data)

    if (data.length > 0) {
      var sum = data.reduce((n: any, {value}: any) => n + value, 0)

      setProjectCost(sum)

      if (updateParentProjectCost) updateParentProjectCost(sum)
    }
  }

  return (
    <>
      <Modal isOpen={show} onHide={() => {}} zIndex={1000} size='lg'>
        <ModalHeader closeButton>
          <h5 className='modal-title'>Project Cost </h5>
        </ModalHeader>
        <ModalBody>
          <div className='card-body' style={{maxHeight: '800px', overflow: 'scroll'}}>
            <table className='table align-middle gs-0 gy-4'>
              <TableHeader
                sort={'id'}
                direction={'asc'}
                columns={[
                  {name: 'WO', static: true},
                  {name: 'Type', static: true},
                  {name: 'Value', static: true},
                ]}
              />
              {/* end::Table head */}
              {/* begin::Table body */}

              {!loading && (
                <tbody>
                  {!loading &&
                    items.map((item) => {
                      var textClass = item.isSubmittedCharge ? 'text-danger' : 'text-success'
                      return (
                        <tr key={item.id}>
                          <td className={`text-dark fw-bolder mb-1 fs-6`}>
                            {item.workOrderNumber}
                          </td>
                          <td className={`text-dark fw-bolder mb-1 fs-6`}>
                            {item.chargeType?.name}
                          </td>
                          <td className={`${textClass} fw-bolder mb-1 fs-6`}>
                            {toMoneyFormat(item.value)}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Loading...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </table>
          </div>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-between'>
          <div className='text-dark fw-bolder fs-6'>Total Cost: {toMoneyFormat(projectCost)}</div>
          <button
            type='button'
            onClick={() => {
              handleClose()
            }}
            className='btn btn-light'
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default WorkOrderProjectCostModal
