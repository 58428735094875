import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import {
    AttachmentsQueryParams,
    AttachmentsClient,
} from '../../../infrastracture/api/AttachmentsClient'
import { TableHeader } from '../../../common/components/TableHeader'
import Pagination from 'react-responsive-pagination'
import RecordPerPageSelector from '../../../common/components/selectors/RecordPerPageSelector'
import Moment from 'react-moment'
import { AttachmentModes } from '../attachmentModes'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import AttachmentsViewModal from './AttachmentsViewModal'
import UploadFromLibraryModal from '../../library/components/UploadFromLibraryModal'
import { SweetAlertWithProp } from '../../../../SweetAlertWithProp'
import _ from 'lodash'
import AttachmentsMultiModal from './AttachmentsMultiModal'
import AttachmentsModal from './AttachmentsModal'
import {Permissions} from "../../../common/constants/permissions";
import {OverlayTrigger, Tooltip} from "react-bootstrap-v5";

interface Props {
    workOrderId?: string
    proposalId?: string
    assetId?: string
    contractId?: string
    quoteId?: string
    reviewId?: string
    hazardId?: string
    incidentId?: string
    incidentActionId?: string
    imagesOnly?: boolean
    hideDelete?: boolean
    tenantId?: string
    refreshParentList: () => void
}

const AttachmentsList: React.FC<Props> = (props) => {

    const permissions: any = useSelector<RootState>(
        ({ auth }) => auth.userPermission,
        shallowEqual
    ) as any
    
    const {
        workOrderId,
        proposalId,
        reviewId,
        refreshParentList,
        assetId,
        contractId,
        hazardId,
        incidentId,
        incidentActionId,
        imagesOnly,
        hideDelete,
        tenantId
    } = props
    const [attachments, setAttachments] = useState<Array<any>>([])
    const [search, setSearch] = useState('')
    const [showDelete, setShowDelete] = useState(false)
    const [selectedData, setSelectedData] = useState<any>({})
    const [mode, setMode] = useState(AttachmentModes.Add)
    const [viewOnly, setViewOnly] = useState(false)

    const [selectAll, setSelectAll] = useState<any>(false)

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalRecords: 1,
        totalPages: 1,
        skip: 0,
        take: 50,
        reset: true,
        sort: 'fileName',
        direction: 'asc',
    })
    const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any

    const [loading, setLoading] = useState(false)
    const [showFromLibraryModal, setShowFromLibraryModal] = useState(false)
    const [checkedItems, setCheckedItems] = useState<any[]>([])

    useEffect(() => {
        fetchData()
    }, [workOrderId, imagesOnly, proposalId])

    useEffect(() => {
        if (pagination.reset) fetchData()
    }, [search, pagination])

    async function fetchData() {
        setLoading(true)
        try {
            var query = new AttachmentsQueryParams()

            if (workOrderId) query.WithWorkOrderId(workOrderId)
            if (assetId) query.WithAssetId(assetId)
            if (contractId) query.WithContractId(contractId)
            if (hazardId) query.WithHazardId(hazardId)
            if (incidentId) query.WithIncidentId(incidentId)
            if (incidentActionId) query.WithIncidentActionId(incidentActionId)
            if (imagesOnly) query.WithImagesOnly(true)
            if (reviewId) query.WithReviewId(reviewId)
            if (proposalId) query.WithProposalId(proposalId)

            var response = await AttachmentsClient.List(
                query
                    .Paginate(pagination.skip, pagination.take)
                    .Search(search)
                    .Sort(pagination.sort, pagination.direction)
            )

            if (!response.authenticated) {
            } else {
                const { data } = response
                setAttachments(data?.data)

                const totalPages = Math.ceil(data.total / pagination.take)

                setPagination({
                    ...pagination,
                    totalPages,
                    totalRecords: data.total,
                    reset: false,
                })
            }
            setLoading(false)
        } catch (e) {
            console.error(e)
        }
    }

    const renderModals = () => {
        return (
            <>
                <AttachmentsModal
                    viewOnly={viewOnly}
                    mode={mode}
                    selectedData={selectedData}
                    handleOk={() => {
                        fetchData()
                        refreshParentList()
                    }}
                    workOrderId={workOrderId}
                    proposalId={proposalId}
                    assetId={assetId}
                    contractId={contractId}
                    hazardId={hazardId}
                    incidentId={incidentId}
                    incidentActionId={incidentActionId}
                    imagesOnly={!!(hazardId || incidentId || incidentActionId)}
                />
                <AttachmentsMultiModal
                    viewOnly={viewOnly}
                    handleOk={() => {
                        fetchData()
                        refreshParentList()
                    }}
                    workOrderId={workOrderId}
                    proposalId={proposalId}
                    assetId={assetId}
                    contractId={contractId}
                    hazardId={hazardId}
                    incidentId={incidentId}
                    incidentActionId={incidentActionId}
                    imagesOnly={!!(hazardId || incidentId || incidentActionId)}
                    tenantId={tenantId}
                />
                <AttachmentsViewModal selectedData={selectedData} />
                <UploadFromLibraryModal
                    workSafeOnly={false}
                    handleClose={() => {
                        setShowFromLibraryModal(false)
                        fetchData()
                    }}
                    handleOk={() => {
                        setShowFromLibraryModal(false)
                        fetchData()
                        refreshParentList()
                    }}
                    show={showFromLibraryModal}
                    workOrderId={workOrderId || ''}
                    uploadFile={async (item: any) => {
                        await AttachmentsClient.UploadAttachmentToWorkOrder(
                            item.id,
                            workOrderId || ''
                        )

                        fetchData()
                        refreshParentList()
                    }}
                />
            </>
        )
    }

    const deleteModals = () => {
        return (
            <SweetAlertWithProp
                danger
                title='Warning'
                onConfirm={async () => {

                    setShowDelete(false)
                    await AttachmentsClient.Delete(selectedData.id)
                    await fetchData()
                    setShowDelete(false)
                }}
                onCancel={() => setShowDelete(false)}
                confirmBtnText={'Confirm'}
                cancelBtnBsStyle='light'
                show={showDelete}
                showCancel={true}
                showCloseButton
            >
                <span>
                    <p>
                        Are you sure you want to delete file <b>{selectedData.name}</b>?
                    </p>
                </span>
            </SweetAlertWithProp>
        )
    }

    return (
        <>
            {deleteModals()}
            {renderModals()}
            <div className={`card d-flex flex-row-fluid flex-start`}>
                <div className='card-header'>
                    <div className='card-title'>
                        <div className='d-flex align-items-center position-relative my-1'>
                            <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen004.svg')} alt="Search icon for input" />
                            </span>
                            <input
                                type='text'
                                data-kt-notice-type-table-filter='search'
                                className='form-control form-control-solid w-250px ps-14'
                                placeholder='Search File'
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                            />
                            {loading && (
                                <div className='d-flex align-items-center'>
                                    <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                                </div>
                            )}{' '}
                        </div>
                    </div>

                    <div className='card-toolbar'>
                        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>

                            {checkedItems.length > 0 && (
                                <button
                                    type='button'
                                    className='btn btn-sm btn-light-primary ml-2 me-2'
                                    onClick={() => {
                                        setSelectAll(false)
                                        let interval = setInterval(download, 300, checkedItems)

                                        function download(checkedItems: any) {
                                            let item = checkedItems.pop()

                                            let a = document.createElement('a')
                                            a.setAttribute('href', item.uri)
                                            a.setAttribute('download', item.name)
                                            a.click()

                                            if (checkedItems.length == 0) {
                                                clearInterval(interval)
                                            }
                                        }

                                        setCheckedItems([])
                                    }}
                                >
                                    <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-3' /> Download selected
                                </button>
                            )}{' '}
                            {workOrderId && (
                                <button
                                    type='button'
                                    className='btn btn-sm btn-light-primary me-2'
                                    onClick={() => {
                                        setShowFromLibraryModal(true)
                                    }}
                                >
                                    <KTSVG path='/media/icons/duotune/general/gen028.svg' className='svg-icon-3' /> Add From Library
                                </button>
                            )}{' '}

                            {(assetId || contractId) && <button
                                type='button'
                                className='btn btn-sm btn-light-primary'
                                data-bs-toggle='modal'
                                data-bs-target='#attachment_modal'
                                onMouseOver={() => {
                                    setMode(AttachmentModes.Add)
                                    setViewOnly(false)
                                }}
                                onClick={() => {
                                    setMode(AttachmentModes.Add)
                                    setViewOnly(false)
                                }}>
                                <KTSVG path='/media/icons/duotune/files/fil018.svg' className='svg-icon-3' /> Upload files
                            </button>}

                            {!assetId && !contractId && <button
                                type='button'
                                className='btn btn-sm btn-light-primary'
                                data-bs-toggle='modal'
                                data-bs-target='#attachment_add_modal'>
                                <KTSVG path='/media/icons/duotune/files/fil018.svg' className='svg-icon-3' /> Upload files
                            </button>}

                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table align-middle gs-0 gy-4'>
                            <TableHeader
                                sort={pagination.sort}
                                direction={pagination.direction}
                                columns={
                                    assetId || contractId
                                        ? [
                                            {
                                                name: (<div className='form-check form-check-solid' style={{ margin: '0 10px' }}>
                                                    <input
                                                        className='form-check-input pe-2'
                                                        type='checkbox'
                                                        checked={selectAll}
                                                        id='selectAll'
                                                        name='selectAll'
                                                        disabled={viewOnly}
                                                        onClick={(e: any) => {
                                                            setSelectAll(e.target.checked)
                                                            if (e.target.checked) {
                                                                setCheckedItems([...attachments])
                                                            } else {
                                                                setCheckedItems([])
                                                            }
                                                        }}
                                                    />
                                                </div>), static: true
                                            },
                                            { name: 'Name', sort: 'name' },
                                            { name: 'Type', sort: 'library.name' },
                                            { name: 'Created By', sort: 'createdBy.name' },
                                            { name: 'Expiry Date', sort: 'expiryDate' },
                                            { name: '', sort: '', static: true },
                                        ]
                                        : [
                                            {
                                                name: (<div className='form-check form-check-solid' style={{ margin: '0 10px' }}>
                                                    <input
                                                        className='form-check-input pe-2'
                                                        type='checkbox'
                                                        checked={selectAll}
                                                        id='selectAll'
                                                        name='selectAll'
                                                        disabled={viewOnly}
                                                        onClick={(e: any) => {
                                                            setSelectAll(e.target.checked)
                                                            if (e.target.checked) {
                                                                setCheckedItems([...attachments])
                                                            } else {
                                                                setCheckedItems([])
                                                            }
                                                        }}
                                                    />
                                                </div>), static: true
                                            },
                                            { name: 'Name', sort: 'name' },
                                            { name: 'Type', sort: 'library.name' },
                                            { name: 'Created By', sort: 'createdBy.name' },
                                            { name: 'Created On', sort: 'created' },
                                            { name: 'Provider', sort: 'fileProvider' },
                                            { name: '', sort: '', static: true },
                                        ]
                                }
                                onUpdate={(sort: any, direction: any) => {
                                    setPagination({
                                        ...pagination,
                                        sort,
                                        direction,
                                        reset: true,
                                    })
                                }}
                            />
                            {/* end::Table head */}
                            {/* begin::Table body */}

                            {!loading && (
                                <tbody>
                                    {!loading &&
                                        attachments.map((item) => (
                                            <tr key={item.id}>
                                                <td>
                                                    <div className='form-check form-check-solid' style={{ margin: '0 10px' }}>
                                                        <input
                                                            className='form-check-input pe-2'
                                                            type='checkbox'
                                                            checked={
                                                                !!_.find(checkedItems, function (o) {
                                                                    return o.id === item.id
                                                                })
                                                            }
                                                            id='active'
                                                            name='active'
                                                            disabled={viewOnly}
                                                            onClick={(e: any) => {
                                                                if (e.target.checked) {
                                                                    setCheckedItems([...checkedItems, item])
                                                                } else {
                                                                    let items = checkedItems.filter((x: any) => x.id !== item.id)
                                                                    setCheckedItems(items)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    {item.name && <label className='text-dark fw-bolder fs-6'>{item.name}</label>}
                                                    {!item.name && <i>Not specified</i>}
                                                </td>

                                                <td>
                                                    {item.library && !item.documentType && (
                                                        <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                                                            {item.library?.documentType?.name}
                                                        </span>
                                                    )}

                                                    {item.documentType && (
                                                        <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                                                            {item.documentType?.name}
                                                        </span>
                                                    )}

                                                    {!item.library && !item.documentType && (
                                                        <span className='text-dark fw-bolder d-block mb-1 fs-6'>Mobile</span>
                                                    )}
                                                </td>

                                                <td>
                                                    {item.createdBy && (
                                                        <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                                                            {item.createdBy.name} {item.createdBy.surname}
                                                        </span>
                                                    )}
                                                    {!item.createdBy && <i>Not specified</i>}
                                                </td>

                                                {assetId || contractId ? (
                                                    <td>
                                                        <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                                                            <Moment utc local format='ddd DD MMM, YYYY '>
                                                                {item.expiryDate}
                                                            </Moment>
                                                        </span>
                                                    </td>
                                                ) : (
                                                    <><td>
                                                        <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                                                            <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                                                                {item.created}
                                                            </Moment>
                                                        </span>
                                                    </td>
                                                        <td>
                                                            <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                                                                {item.fileProvider === "azureStorage" && <span className='badge badge-light-info'>Azure</span>}
                                                                {item.fileProvider === "mFiles" && <span className='badge badge-light-success'>M-Files ({item.mFilesDocumentId})</span>}

                                                                {item.fileProvider === "mFiles" && item.syncSuccessful && (
                                                                    <OverlayTrigger placement='bottom' overlay={<Tooltip id={item.id}>Sync successful</Tooltip>}>
                                                                        <i className="bi bi-check2-circle me-2" style={{ color: "green" }}></i>
                                                                    </OverlayTrigger>
                                                                )}

                                                                {item.fileProvider === "mFiles" && !item.syncSuccessful && item.syncMessage && (
                                                                    <OverlayTrigger placement='bottom' overlay={<Tooltip id="supplier-bad">{item.syncMessage}</Tooltip>}>
                                                                        <i className="bi bi-slash-circle-fill me-2" style={{ color: "red" }}></i>
                                                                    </OverlayTrigger>
                                                                )}
                                                            </span>
                                                        </td></>
                                                )}

                                                <td className='text-end'>
                                                    {!assetId && !contractId && item.mFilesLink && <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className='btn btn-bg-light btn-color-primary btn-active-color-primary btn-sm'
                                                        href={item.mFilesLink}>
                                                        <KTSVG
                                                            path='/media/icons/duotune/files/fil020.svg'
                                                            className='svg-icon-3 m-0'
                                                        />
                                                    </a>}
                                                    <button
                                                        type='button'
                                                        className='btn btn-bg-light btn-color-primary btn-active-color-primary btn-sm'
                                                        data-bs-toggle='modal'
                                                        data-bs-target='#attachment_modal'
                                                        onMouseOver={() => {
                                                            setSelectedData(item)
                                                            setViewOnly(false)
                                                            setMode(AttachmentModes.Edit)
                                                        }}
                                                        onClick={() => {
                                                            setSelectedData(item)
                                                            setViewOnly(false)
                                                            setMode(AttachmentModes.Edit)
                                                        }}
                                                    >
                                                        <KTSVG
                                                            path='/media/icons/duotune/general/gen055.svg'
                                                            className='svg-icon-3 m-0'
                                                        />
                                                    </button>{' '}
                                                    {item.fileName.match(/.(jpg|jpeg|png|gif)$/i) && (
                                                        <button
                                                            type='button'
                                                            className='btn btn-bg-light btn-color-primary btn-active-color-primary btn-sm'
                                                            data-bs-toggle='modal'
                                                            data-bs-target='#attachment_view_modal'
                                                            onMouseOver={() => {
                                                                setSelectedData(item)
                                                            }}
                                                            onClick={() => {
                                                                setSelectedData(item)
                                                            }}
                                                        >
                                                            <KTSVG
                                                                path='/media/icons/duotune/general/gen045.svg'
                                                                className='svg-icon-3 m-0'
                                                            />
                                                        </button>
                                                    )}{' '}
                                                    <button className='btn btn-bg-light btn-color-primary btn-active-color-primary btn-sm'
                                                        onClick={() => {
                                                            const link = document.createElement('a')
                                                            link.href = item.uri
                                                            link.click()
                                                        }}
                                                    >
                                                        <KTSVG
                                                            path='/media/icons/duotune/files/fil017.svg'
                                                            className='svg-icon-3 m-0'
                                                        />
                                                    </button>{' '}
                                                    {((user.tenantId === item.createdBy?.tenantId) || permissions?.includes(Permissions.MANAGE_ATTACHMENTS)) && !hideDelete && (
                                                        <button
                                                            type='button'
                                                            className='btn btn-bg-light btn-color-danger btn-active-color-danger btn-sm'
                                                            onMouseOver={() => {
                                                                setSelectedData(item)
                                                            }}
                                                            onClick={() => {
                                                                setSelectedData(item)
                                                                setShowDelete(true)
                                                            }}
                                                        >
                                                            <KTSVG
                                                                path='/media/icons/duotune/files/fil015.svg'
                                                                className='svg-icon-3 m-0'
                                                            />
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            )}
                        </table>
                    </div>

                    <div className='card-footer px-1 py-4'>
                        <div className='d-flex justify-content-between'>
                            <div style={{ minWidth: '200px' }}>
                                <RecordPerPageSelector
                                    placeholder='Rows per page'
                                    onChange={(name, value) => {
                                        setPagination({
                                            ...pagination,
                                            take: value,
                                            reset: true,
                                            currentPage: 1,
                                        })
                                    }}
                                />
                            </div>
                            <div data-kt-notice-type-table-toolbar='base'>
                                <Pagination
                                    current={pagination.currentPage}
                                    total={pagination.totalPages}
                                    onPageChange={(page) => {
                                        setPagination({
                                            ...pagination,
                                            currentPage: page,
                                            skip: page === 1 ? 0 : (page - 1) * pagination.take,
                                            reset: true,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AttachmentsList