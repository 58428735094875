import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import * as Yup from 'yup'
import {Formik, Field, Form, ErrorMessage} from 'formik'

interface Props {
    handleOk: (reason: any) => void
    handleClose: () => void
    show: boolean
}

const QuotesDeclineModal: React.FC<Props> = (props) => {
    const {handleOk, show, handleClose } = props

    const validationSchema = Yup.object().shape({
        reason: Yup.string().required('Reason is required'),
    })

    return (
        <>
            <Formik
                initialValues={{
                    reason: '',
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, {setFieldError}) => {

                    let hasError = false

                    if (!values.reason) {
                        setFieldError('reason', 'Reason is required')
                        hasError = true
                    }

                    if (hasError) return

                    handleOk(values.reason)
                }}
                enableReinitialize
            >
                {({errors, touched, resetForm, submitForm}: any) => (
                    <>
                        <>
                            <Modal isOpen={show} onHide={() => {
                            }} zIndex={1000} size='lg'>
                                <Form>
                                    <ModalHeader closeButton>
                                        <h5 className='modal-title'>Decline Quote</h5>
                                    </ModalHeader>
                                    <ModalBody>
                                        <label className='required form-label'>Reason</label>
                                        <div style={{display: 'flex'}}>
                                            <Field
                                                name='reason'
                                                placeholder=''
                                                type='text'
                                                className={
                                                    'form-control' +
                                                    (errors.reason && touched.reason ? ' is-invalid' : '')
                                                }
                                                id='reason'
                                            />
                                        </div>
                                        <ErrorMessage
                                            name='reason'
                                            component='div'
                                            className='invalid-feedback text-left'
                                        />
                                    </ModalBody>
                                    <ModalFooter>
                                        <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                                            <button
                                                type='button'
                                                onClick={() => {
                                                    resetForm()
                                                    handleClose()
                                                }}
                                                className='btn btn-light'
                                            >
                                                Close
                                            </button>

                                            <button
                                                type='button'
                                                onClick={() => {
                                                    submitForm()
                                                }}
                                                className='btn btn-light'
                                            >
                                                Decline
                                            </button>
                                        </div>
                                    </ModalFooter>
                                </Form>
                            </Modal>
                        </>
                    </>
                )}
            </Formik>
        </>
    )
}

export default QuotesDeclineModal
