import React, {useEffect, useState} from 'react'
import {Link, Redirect, Route, Switch, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {UserQueryParams, UsersClient} from '../../../infrastracture/api/UsersClient'
import {WorkOrderQueryParams, WorkOrderClient} from '../../../infrastracture/api/WorkOrderClient'
import {TableHeader} from '../../../common/components/TableHeader'
import Pagination from 'react-responsive-pagination'
import RecordPerPageSelector from '../../../common/components/selectors/RecordPerPageSelector'
import Moment from 'react-moment'

interface Props {
  workOrderId: string
}

const WorkOrderHistoryList: React.FC<Props> = (props) => {
  const {workOrderId} = props

  const [history, setHistory] = useState<Array<any>>([])
  const [search, setSearch] = useState('')
  const [active, setActive] = useState(true)
  const [selectedData, setSelectedData] = useState<any>({})
  const [mode, setMode] = useState('')
  const [viewOnly, setViewOnly] = useState(false)

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalRecords: 1,
    totalPages: 1,
    skip: 0,
    take: 50,
    reset: true,
    sort: 'name',
    direction: 'asc',
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (pagination.reset) fetchData()
  }, [search, pagination])

  useEffect(() => {
    fetchData()
  }, [workOrderId])

  async function fetchData() {
    setLoading(true)
    try {
      let query = new WorkOrderQueryParams()

      let response = await WorkOrderClient.GetHistory(
        workOrderId,
        query
          .Paginate(pagination.skip, pagination.take)
          .Search(search)
          .WithActive(active)
          .WithStatusChanged(true)
          .Sort(pagination.sort, pagination.direction)
      )

      console.log(response)
      
      if (!response.authenticated) {
      } else {
        const {data} = response
        setHistory(data)
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <div className={`card`}>
        <div className='card-header'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <h2 className='text-gray-600'>Work Order History</h2>
              {loading && (
                <div className='d-flex align-items-center'>
                  <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                </div>
              )}
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'></div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <TableHeader
                sort={pagination.sort}
                direction={pagination.direction}
                columns={[
                  {name: 'Updated', sort: 'periodEnd', width: '15'},
                  {name: 'Status', sort: 'status.name', width: '15'},
                  {name: 'Updated By', sort: 'active', width: '15'},
                ]}
                onUpdate={(sort: any, direction: any) => {
                  setPagination({
                    ...pagination,
                    sort,
                    direction,
                    reset: true,
                  })
                }}
              />
              {/* end::Table head */}
              {/* begin::Table body */}

              {!loading && (
                <tbody>
                  {!loading &&
                    history.map((item) => (
                        <tr key={item.id}>
                          <td>
                            {item.periodEnd && (
                                <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                                  <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                                    {item.periodEnd}
                                  </Moment>
                                </a>
                            )}
                            {!item.periodEnd && <span className='text-dark fw-bolder d-block mb-1 fs-6'><i>Current</i></span>}
                          </td>

                          <td>
                            <div className='d-flex align-items-center rounded p-5'>
                              {item.status?.color && (
                                  <span
                                      className={`bullet bullet-vertical h-30px `}
                                      style={
                                          item.status?.color && {
                                            background: item.status?.color,
                                          }
                                      }
                                  ></span>
                              )}{' '}
                              <div
                                  className='flex-grow-1 me-2'
                                  style={
                                      item.status?.color && {
                                        paddingLeft: '5px',
                                      }
                                  }
                              >
                                                            <span className='fw-bolder text-gray-800 fs-6'>
                                                                {item.status?.name}
                                                            </span>
                              </div>
                            </div>
                          </td>
                          
                          <td>
                            {item.updatedBy && (
                                <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                                  {item.updatedBy?.name} {item.updatedBy?.surname}
                                </a>
                            )}
                            {!item.updatedBy && <i>System</i>}
                          </td>
                        </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkOrderHistoryList
