import { ApiClient, PublicApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'
import { GetAPIDomain } from '../Helper'

export class UserQueryParams extends ListQueryParams {
    WithShowSuspended(value: any) {
        if (!value) this.WithParam('status', 'active')
        return this
    }

    WithRoleId(value: any) {
        this.WithParam('roleId', value)
        return this
    }

    WithStatus(value: any) {
        this.WithParam('status', value)
        return this
    }

    WithTeamId(value: any) {
        this.WithParam('teamId', value)
        return this
    }

    WithSiteId(value: any) {
        this.WithParam('siteId', value)
        return this
    }

    WithTenantId(value: any) {
        this.WithParam('tenantId', value)
        return this
    }
    WithTenantTypeId(value: any) {
        this.WithParam('tenantTypeId', value)
        return this
    }
    WithIsMyobOwner(value: any) {
        this.WithParam('isMyobOwner', value)
        return this
    }
    WithIsMyobSupplyChain(value: any) {
        this.WithParam('isMyobSupplyChain', value)
        return this
    }
    WithHasEmployeeId(value: any) {
        this.WithParam('hasEmployeeId', value)
        return this
    }
    WithHasOwnerId(value: any) {
        this.WithParam('hasOwnerId', value)
        return this
    }
}

export class UsersClient {
    static async List(params: UserQueryParams) {
        return await ApiClient.Get('/users' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/users/' + id);
    }

    static async GetCurrentUser(email: string) {
        return await ApiClient.Get('/users/current/' + email);
    }

    static async GetCurrentAuthenticatedUser() {
        return await ApiClient.Get('/users/current/authenticated');
    }

    static async SetRole(id: string, roleId: any) {
        return await ApiClient.Post('/users/' + id + '/set-roles', {
            roleIds: roleId,
        });
    }

    static async SetTeam(userId: string, teamId: string) {

        return await ApiClient.Post('/users/' + userId + '/set-team', {
            userId: userId,
            teamId: teamId,
        });
    }

    static async Suspend(id: string) {
        return await ApiClient.Post('/users/' + id + '/suspend');
    }
    static async Restore(id: string) {
        return await ApiClient.Post('/users/' + id + '/restore');
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/users/' + id);
    }

    static async Update(
        id: string,
        name: string,
        surname: string,
        email: string,
        tenantId: string,
        mobile?: string,
        siteId?: string,
        hasCrmAccess?: boolean,
        receiveWorkOrderUpdates?: boolean,
        rateCardId?: string
    ) {

        return await ApiClient.Patch('/users/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'surname',
                value: surname,
            },
            {
                op: 'replace',
                path: 'email',
                value: email,
            },
            {
                op: 'replace',
                path: 'tenantId',
                value: tenantId,
            },
            {
                op: 'replace',
                path: 'mobile',
                value: mobile,
            },
            {
                op: 'replace',
                path: 'siteId',
                value: siteId,
            },
            {
                op: 'replace',
                path: 'hasCrmAccess',
                value: hasCrmAccess,
            },
            {
                op: 'replace',
                path: 'receiveWorkOrderUpdates',
                value: receiveWorkOrderUpdates,
            },
            {
                op: 'replace',
                path: 'rateCardId',
                value: rateCardId,
            },
        ]);
    }

    static async Create(
        name: string,
        surname: string,
        email: string,
        tenantId: string,
        mobile?: string,
        hasCrmAccess?: boolean,
        roleId?: string,
        sendInvite?: boolean,
        receiveWorkOrderUpdates?: boolean
    ) {

        return await ApiClient.Post('/users/', {
            name: name,
            surname: surname,
            mobile: mobile,
            email: email,
            tenantId: tenantId,
            hasCrmAccess: hasCrmAccess,
            roleId: roleId,
            sendInvite,
            receiveWorkOrderUpdates
        });
    }

    static async UploadPhoto(userId: string, file: any) {
        let formData = new FormData()
        formData.append('userId', userId)
        formData.append('file', file)

        let accessToken = localStorage.getItem('access-token-v2')

        const response = await fetch((await GetAPIDomain()) + '/users/upload-photo', {
            method: 'post',
            headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
            body: formData,
        })

        if (!response.ok) {
            return false
        }

        await response.json()
    }

    static async BulkCreate(roleId: string, tenantId: string, file: any, sendInvite: boolean) {
        let formData = new FormData()
        formData.append('roleId', roleId)
        formData.append('tenantId', tenantId)
        formData.append('file', file)
        formData.append('sendInvite', sendInvite ? 'true' : 'false')

        let accessToken = localStorage.getItem('access-token-v2')

        const response = await fetch((await GetAPIDomain()) + '/users/bulk', {
            method: 'post',
            headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
            body: formData,
        })

        if (!response.ok) {
            return false
        }

        return await response.json()
    }

    static async DeletePhoto(id: string) {
        return await ApiClient.Delete('/users/' + id + '/remove-photo');
    }

    static async GenerateTwoFactorAuth(email: string) {
        return await PublicApiClient.Post('/users/generate-two-factor-auth', {
            email: email,
        });
    }

    static async CheckTwoFactorAuth(email: string, token: string) {

        return await PublicApiClient.Post('/users/check-two-factor-auth', {
            email: email,
            token: token,
        });

    }

    //BEGIN PASSWORD RESET
    static async BeginPasswordReset(email: string) {
        return await PublicApiClient.Post('/reset-password/begin', {
            email: email,
            isForCRM: true,
        });
    }

    //COMPLETE PASSWORD RESET
    static async CompletePasswordReset(code: string, password: string) {
        return await PublicApiClient.Post('/reset-password/complete', {
            code: code,
            password: password,
        });
    }

    static async AddReceiveUpdates(userId: string, tenantId: string, deleteOthers?: boolean) {

        return await ApiClient.Post(`/users/set-receive-updates`, {
            userId,
            tenantId,
            deleteOthers,
        });

    }
    static async RemoveReceiveUpdates(id: string) {
        return await ApiClient.Delete(`/users/set-receive-updates/${id}`);
    }
}
