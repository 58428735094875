import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Step1} from './wizardSteps/Step1'
import {Step2} from './wizardSteps/Step2'
import {Step3} from './wizardSteps/Step3'
import {Step4} from './wizardSteps/Step4'
import {Step5} from './wizardSteps/Step5'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import * as Yup from 'yup'
import {
  Formik,
  Field,
  Form,
  FormikHelpers,
  useFormik,
  ErrorMessage,
  yupToFormErrors,
  FormikValues,
} from 'formik'
import {CheckInsClient, CheckInsQueryParams} from '../../../infrastracture/api/CheckInsClient'
import {
  NoticeSitesClient,
  NoticeSitesQueryParams,
} from '../../../infrastracture/api/NoticeSitesClient'
import SweetAlert from 'react-bootstrap-sweetalert'
import moment from 'moment'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'

interface Props {
  handleOk: () => void
  checkIn: any
  workOrder: any
  user: any
  workOrderId?: string
  siteId?: any
  loading: boolean
  viewOnly?: boolean
}

const validationSchema = Yup.object().shape({})

const CheckInsModal: React.FC<Props> = (props) => {
  const {user, checkIn, loading, workOrder, siteId, handleOk, workOrderId} = props
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [initValues] = useState<any>({})
  const [notices, setNotices] = useState([])
  const [noticeAcknowledged, setNoticeAcknowledged] = useState(false)
  const [minutes, setMinutes] = useState<any>(30)
  const [expectedOut, setExpectedOut] = useState<any>('')
  const [files, setFiles] = useState<any[]>([])
  const [workSafeFiles, setWorkSafeFiles] = useState<any[]>([])
  const [additionalWorkSafeFiles, setAdditionalWorkSafeFiles] = useState<any[]>([])
  const [createSuccess, setCreateSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [acknowledgedSWMS, setAcknowledgedSWMS] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [hasSafetyCons, setHasSafetyCons] = useState(false)
  const [safetyCons, setSafetyCons] = useState('')

  useEffect(() => {
    if (siteId) fetchNotices()
  }, [siteId])

  const fetchNotices = async () => {
    var query = new NoticeSitesQueryParams()

    var response = await NoticeSitesClient.List(
      query.Paginate(0, 99999).WithSiteId(siteId).Sort('created', 'desc')
    )
    if (response.data) {
      setNotices(response.data?.data)
    }
  }

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  const resetForm = async () => {
    setNoticeAcknowledged(false)
    setFiles([])
    setWorkSafeFiles([])
    setAdditionalWorkSafeFiles([])
    setMinutes(30)
    setCurrentStep(1)
    stepper.current?.goto(1)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    setCurrentStep(currentStep - 1)
    stepper.current.goPrev()
  }

  const submitStep = async (values: any, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      setCurrentStep(currentStep + 1)
      stepper.current.goNext()
    } else {
      setSubmitting(true)
      var expectedCheckOut = new Date(
        moment().add(minutes, 'minutes').format('ddd DD MMM, YYYY h:mm a')
      ).toISOString()

      var checkInDateTime = new Date(moment().format('ddd DD MMM, YYYY h:mm a')).toISOString()

      var response = await CheckInsClient.Update(
        checkIn.id,
        checkInDateTime,
        minutes,
        null, //coord.lat == 0 ? null : coord.lat,
        null, //coord.long == 0 ? null : coord.long,
        expectedCheckOut,
        null,
        false,
        '',
        safetyCons
      )

      if (!response.successful) {
        //show error
      } else {
        resetForm()
        document.getElementById('rd_check_in_modal_dismiss_hidden')!.click()
        setCreateSuccess(true)
        setSubmitting(false)
      }
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <>
      <SweetAlertWithProp
        success
        title='Success'
        onConfirm={() => {
          setCreateSuccess(false)
          handleOk()
        }}
        confirmBtnText='Confirm'
        cancelBtnBsStyle='light'
        show={createSuccess}
        showCancel={false}
        showCloseButton
      >
        Check In Success!
      </SweetAlertWithProp>
      <div
        className='modal fade'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
        aria-hidden='true'
        id='check_in_modal'
      >
        <div className='modal-dialog modal-dialog-centered mw-900px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                {loading ? '' : `${workOrder?.workOrderNumber}`} Check In for {user?.name}{' '}
                {user?.surname}
              </h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <span className='svg-icon svg-icon-2x'></span>
              </div>
            </div>

            <div className='modal-body' style={loading ? {pointerEvents: 'none'} : {}}>
              {loading && (
                <h5>
                  {' '}
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </h5>
              )}
              {!loading && checkIn.errorMessage && (
                <span className='text-danger'>
                  <b>{checkIn.errorMessage}</b>
                </span>
              )}
              <div
                style={!loading && !checkIn.errorMessage ? {display: 'block'} : {display: 'none'}}
              >
                {' '}
                <div
                  ref={stepperRef}
                  className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                  id='kt_create_account_stepper'
                >
                  <div className='d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto'>
                    <div className='px-6 px-lg-10 py-20'>
                      <div className='stepper-nav'>
                        <div className='stepper-item current' data-kt-stepper-element='nav'>
                          <div className='stepper-line w-40px'></div>

                          <div className='stepper-icon w-40px h-40px'>
                            <i className='stepper-check fas fa-check'></i>
                            <span className='stepper-number'>1</span>
                          </div>

                          <div className='stepper-label'>
                            <h3 className='stepper-title'>Notices</h3>

                            {/* <div className='stepper-desc fw-bold'>Notices</div> */}
                          </div>
                        </div>

                        <div className='stepper-item' data-kt-stepper-element='nav'>
                          <div className='stepper-line w-40px'></div>

                          <div className='stepper-icon w-40px h-40px'>
                            <i className='stepper-check fas fa-check'></i>
                            <span className='stepper-number'>2</span>
                          </div>

                          <div className='stepper-label'>
                            <h3 className='stepper-title'>Time On Site</h3>
                            {/* <div className='stepper-desc fw-bold'>Time On Site</div> */}
                          </div>
                        </div>

                        <div className='stepper-item' data-kt-stepper-element='nav'>
                          <div className='stepper-line w-40px'></div>

                          <div className='stepper-icon w-40px h-40px'>
                            <i className='stepper-check fas fa-check'></i>
                            <span className='stepper-number'>3</span>
                          </div>

                          <div className='stepper-label'>
                            <h3 className='stepper-title'>Attach Files</h3>
                            {/* <div className='stepper-desc fw-bold'>Upload SWMS</div> */}
                          </div>
                        </div>

                        <div className='stepper-item' data-kt-stepper-element='nav'>
                          <div className='stepper-line w-40px'></div>

                          <div className='stepper-icon w-40px h-40px'>
                            <i className='stepper-check fas fa-check'></i>
                            <span className='stepper-number'>4</span>
                          </div>

                          <div className='stepper-label'>
                            <h3 className='stepper-title'>Safety Considerations</h3>
                            {/* <div className='stepper-desc fw-bold'>Upload SWMS</div> */}
                          </div>
                        </div>

                        <div className='stepper-item' data-kt-stepper-element='nav'>
                          <div className='stepper-line w-40px'></div>

                          <div className='stepper-icon w-40px h-40px'>
                            <i className='stepper-check fas fa-check'></i>
                            <span className='stepper-number'>5</span>
                          </div>

                          <div className='stepper-label'>
                            <h3 className='stepper-title'>Confirm Check In</h3>
                            {/* <div className='stepper-desc fw-bold'>Confirm Check In</div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
                    <Formik
                      validationSchema={validationSchema}
                      initialValues={initValues}
                      onSubmit={submitStep}
                    >
                      {() => (
                        <Form
                          className='py-1 w-200 w-xl-700px px-1'
                          noValidate
                          id='kt_create_account_form'
                        >
                          <div className='current' data-kt-stepper-element='content'>
                            <Step1
                              checkIn={checkIn}
                              notices={notices}
                              handleOk={() => {
                                setCurrentStep(currentStep + 1)
                                stepper?.current?.goNext()
                                setNoticeAcknowledged(true)
                              }}
                            />
                          </div>

                          <div data-kt-stepper-element='content'>
                            <Step2
                              checkIn={checkIn}
                              handleOk={(minutes, expectedOut) => {
                                setMinutes(minutes)
                                if (expectedOut) setExpectedOut(expectedOut)
                              }}
                            />
                          </div>

                          <div data-kt-stepper-element='content'>
                            <Step3
                              checkInId={checkIn.id}
                              workOrderId={workOrderId}
                              handleOk={(files) => {
                                setFiles(files)
                              }}
                              acknowledgeSWMS={(value: boolean) => {
                                setAcknowledgedSWMS(value)
                              }}
                              setDefaultWorkSafeFiles={(items: any) => {
                                setWorkSafeFiles(items)
                              }}
                              updateAddiotnalWorkSafeFiles={(newItem: any) => {
                                setAdditionalWorkSafeFiles([...additionalWorkSafeFiles, newItem])
                              }}
                              user={user}
                            />
                          </div>

                          <div data-kt-stepper-element='content'>
                            <Step4
                              onCommentChanged={(value) => setSafetyCons(value)}
                              toggleChecked={(value) => setHasSafetyCons(value)}
                              workOrderId={workOrder.id}
                              checkInId={checkIn.id}
                            />
                          </div>

                          <div data-kt-stepper-element='content'>
                            <Step5
                              notices={notices}
                              minutes={minutes}
                              files={files}
                              expectedOut={expectedOut}
                              defaultWorkSafeFiles={workSafeFiles}
                              additionalWorkSafeFiles={additionalWorkSafeFiles}
                              safetyConsideration={safetyCons}
                            />
                          </div>

                          <div className='d-flex flex-stack pt-10'>
                            <div className='mr-2'>
                              <button
                                onClick={prevStep}
                                type='button'
                                className='btn btn-lg btn-light-primary me-3'
                                data-kt-stepper-action='previous'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr063.svg'
                                  className='svg-icon-4 me-1'
                                />
                                Back
                              </button>
                            </div>

                            <div>
                              {(noticeAcknowledged || notices.length === 0) && (
                                <button
                                  type='submit'
                                  className='btn btn-lg btn-primary me-3'
                                  disabled={
                                    ((!acknowledgedSWMS ||
                                      (files.length === 0 &&
                                        workSafeFiles.length === 0 &&
                                        additionalWorkSafeFiles.length === 0)) &&
                                      currentStep === 3) ||
                                    (hasSafetyCons && !safetyCons && currentStep === 4)
                                  }
                                >
                                  {!submitting && (
                                    <span className='indicator-label'>
                                      {stepper.current?.currentStepIndex !==
                                        stepper.current?.totatStepsNumber! && 'Continue'}
                                      {stepper.current?.currentStepIndex ===
                                        stepper.current?.totatStepsNumber! && (
                                        <span>
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen013.svg'
                                            className='svg-icon-3'
                                          />{' '}
                                          Check In
                                        </span>
                                      )}
                                      <KTSVG
                                        path='/media/icons/duotune/arrows/arr064.svg'
                                        className='svg-icon-3 ms-2 me-0'
                                      />
                                    </span>
                                  )}

                                  {submitting && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                      Please wait...
                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                  )}
                                </button>
                              )}
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                onClick={async () => {
                  await CheckInsClient.Delete(checkIn.id)
                  resetForm()
                }}
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='rd_check_in_modal_dismiss'
              >
                Cancel
              </button>

              {/* { 
                INFO: Needed a hidden button to just hide the modal after success, 
                because when canceling in the modal itself we need to delete the created base checkin 
              } */}
              <button
                type='button'
                hidden
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='rd_check_in_modal_dismiss_hidden'
              >
                Cancel
              </button>
              {/* {!viewOnly && (
              <button
                type='submit'
                id='rd_create_tenant_submit'
                className='btn btn-lg btn-primary'
                disabled={isSubmitting}
              >
                {!isSubmitting && <span className='indicator-label'>Save</span>}
                {isSubmitting && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckInsModal
