import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import QuoteStatusSelector from '../../../common/components/selectors/QuoteStatusSelector'
import {AttachmentsClient} from '../../../infrastracture/api/AttachmentsClient'
import {QuotesClient} from '../../../infrastracture/api/QuotesClient'

import {Field, Form, Formik} from 'formik'
import Moment from 'react-moment'
import {shallowEqual, useSelector} from 'react-redux'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import * as Yup from 'yup'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'
import {RootState} from '../../../../setup'
import {TenantTypeIds} from '../../../common/constants/tenantTypeIds'
import {toMoneyFormat} from '../../../common/helpers/stringHelper'
import QuotesBreakdownModal from './QuotesBreakdownModal'
import {
  QuoteBreakdownClient,
  QuoteBreakdownQueryParams,
} from '../../../infrastracture/api/QuoteBreakdownClient'
interface Props {
  handleOk: () => void
  handleClose: () => void
  selectedData: any
  mode: string
  workOrderId: string
  thresholdMoney: any
  isFromChildWorkOrder?: boolean
  show: boolean
  viewOnly?: boolean
  isClient?: boolean
  defaultAmount?: any
}

const QuotesModal: React.FC<Props> = (props) => {
  const {
    handleOk,
    selectedData,
    mode,
    viewOnly,
    workOrderId,
    isClient,
    thresholdMoney,
    show,
    defaultAmount,
    handleClose,
    isFromChildWorkOrder,
  } = props

  const [loading, setLoading] = useState(false)
  const [fetchingBreakdowns, setFetchingBreakdowns] = useState(false)
  const [createSuccess, setCreateSuccess] = useState(false)
  const [quote, setQuote] = useState<any>({})
  const [rating, setRating] = useState(0)
  const [file, setFile] = useState('')
  const [showBreakdownModal, setShowBreakdownModal] = useState(false)
  const [quoteBreakdowns, setQuoteBreakdowns] = useState([])
  const params: any = useParams()
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  const canApprove =
    user?.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Client ||
    user?.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner

  const validationSchema = Yup.object().shape({
    referenceNumber: Yup.string().required('Ref # is required'),
    description: Yup.string().required('Activity Description is required'),
    estimate: Yup.string().required('Amount is required'),
  })

  const [initialValues, setInitialValues] = useState<any>({
    referenceNumber: '',
    description: '',
    estimate: '',
  })

  useEffect(() => {
    if (defaultAmount && defaultAmount > 0) {
      setInitialValues({
        ...initialValues,
        estimate: defaultAmount,
      })
    }
  }, [defaultAmount])

  useEffect(() => {
    setLoading(true)
    if (mode === 'Edit' || mode === 'View') {
      var quotesDetails = {...selectedData}

      if (selectedData?.quoteStatus) {
        const quoteStatus = selectedData.quoteStatus
        quotesDetails = {
          ...quotesDetails,
          quoteStatus: {value: quoteStatus.id, label: quoteStatus.name},
          quoteStatusId: quoteStatus.id,
        }
      }

      setInitialValues(quotesDetails)
      setQuote(selectedData)
    } else
      setInitialValues({
        name: '',
        description: '',
        referenceNumber: '',
        estimate: '',
        workOrderStatusId: '',
        quoteStatusId: '',
        quoteStatus: {} as any,
        processedById: '',
        processedBy: {} as any,
        quoteNumberId: '',
        file: '',
        fileName: '',
      })

    setLoading(false)
  }, [selectedData, mode])

  useEffect(() => {
    if (show && selectedData) {
      getQuoteBreakdowns(selectedData.id)
    }
  }, [show])

  const getQuoteBreakdowns = async (quoteId: string) => {
    setFetchingBreakdowns(true)
    var query = new QuoteBreakdownQueryParams()

    var response = await QuoteBreakdownClient.List(query.WithQuoteId(quoteId))
    if (!response.authenticated) {
    } else {
      const {data} = response

      setQuoteBreakdowns(data)
      setFetchingBreakdowns(false)
    }
  }

  const handleRating = (rate: any) => {
    setRating(rate)
  }

  return (
    <>
      <QuotesBreakdownModal
        handleOk={function (quoteId: any): void {
          getQuoteBreakdowns(quoteId)
          setShowBreakdownModal(false)
        }}
        handleClose={function (quoteId?: any): void {
          if (quoteId) getQuoteBreakdowns(quoteId)
          setShowBreakdownModal(false)
        }}
        quoteId={selectedData?.id}
        show={showBreakdownModal}
        quotedValue={quote?.totalCost}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {
          var response: any

          if (values.file && !values.fileName) {
            setFieldError('fileName', 'Please specify the file name')
            return
          }

          if (mode === 'Edit' || mode === 'View') {
            response = await QuotesClient.Update(
              selectedData.id,
              values.name,
              values.description,
              values.referenceNumber,
              values.estimate,
              values.quoteStatusId
            )
          } else {
            response = await QuotesClient.Create(
              workOrderId,
              values.name,
              values.description,
              values.referenceNumber,
              values.estimate
            )
          }

          if (values.file && values.fileName)
            await AttachmentsClient.Create(
              params.id,
              null,
              null,
              null,
              null,
              null,
              null,
              values.fileName,
              values.file,
              response.data.id,
              null,
              false,
              false
            )

          if (!response.successful) {
            //show error
          } else {
            setSubmitting(false)
            document.getElementById('rd_Quotes_modal_dismiss')!.click()
            setCreateSuccess(true)
          }
        }}
        enableReinitialize
      >
        {({errors, touched, isSubmitting, values, setFieldValue, resetForm}: any) => (
          <>
            <SweetAlertWithProp
              success
              title='Success'
              onConfirm={() => {
                setCreateSuccess(false)
                resetForm()
                handleOk()
              }}
              confirmBtnText='Confirm'
              cancelBtnBsStyle='light'
              show={createSuccess}
              showCancel={false}
              showCloseButton
            >
              {mode} Quotes success
            </SweetAlertWithProp>

            {mode === 'View' && (
                <>
                <Modal isOpen={show} onHide={() => {
                }} zIndex={1000} size='lg'>
                  <Form>
                    <ModalHeader closeButton>
                      <h5 className='modal-title'>{mode} Quote</h5>
                    </ModalHeader>
                    <ModalBody>
                      {' '}
                      <table>
                        {(isClient ||
                            user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner) && (
                            <tr>
                              <td colSpan={2}>
                                <label className='required form-label'>Quote Status</label>
                                <QuoteStatusSelector
                                    placeholder='Select Quote Status'
                                    defaultValue={values.quoteStatus}
                                    onChange={setFieldValue}
                                    disabled={isFromChildWorkOrder}
                                />

                                {touched.quoteStatusId && errors.quoteStatusId && (
                                    <div className='fv-plugins-message-container'>
                                  <span className='text-danger' role='alert'>
                                    {errors.quoteStatusId}
                                  </span>
                                    </div>
                                )}
                              </td>
                            </tr>
                        )}
                      </table>
                      <table>
                        <tr>
                          <td style={{paddingRight: '175px'}}>
                            <div className='fs-6'>
                              <div className='fw-bolder mt-5'>QO #</div>
                              <div className='text-gray-600'>QO-{quote?.quoteNumberId}</div>
                            </div>
                          </td>
                          <td style={{paddingRight: '71px'}}>
                            <div className='fs-6'>
                              <div className='fw-bolder mt-5'>Ref #</div>
                              <div className='text-gray-600'>{quote?.referenceNumber}</div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{paddingRight: '152px', verticalAlign: 'top'}}>
                            <div className='fs-6'>
                              <div className='fw-bolder mt-5'>Amount</div>
                              <div className='text-gray-600'>
                                {user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Client
                                    ? `${toMoneyFormat(quote?.totalCost)}`
                                    : `$${quote?.estimate}`}
                              </div>
                            </div>
                          </td>
                          {user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner && (
                              <td>
                                <div className='fs-6'>
                                  <div className='fw-bolder mt-5'>Fees</div>
                                  {quote?.charges && quote?.charges.length > 0 && (
                                      <>
                                        <ul>
                                          {quote?.charges.map((charge: any) => {
                                            return (
                                                <li className='text-gray-600'>
                                                  {toMoneyFormat(charge.value)} -{' '}
                                                  {charge.chargeType?.name}
                                                </li>
                                            )
                                          })}
                                        </ul>
                                      </>
                                  )}
                                </div>
                              </td>
                          )}
                        </tr>
                        {user.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Contractor && (
                            <tr>
                              <td colSpan={2}>
                                <div>
                                  <div className='fw-bolder mt-5 fs-6'>Total</div>
                                  <div className='text-dark-600 fw-bolder fs-5'>
                                    {toMoneyFormat(quote?.totalCost)}
                                  </div>
                                </div>
                              </td>
                            </tr>
                        )}
                      </table>
                      <table>
                        <tr>
                          <td style={{paddingRight: '45px'}}>
                            <div className='fs-6'>
                              <div className='fw-bolder mt-5'>Created On</div>
                              <div className='text-gray-600'>
                                <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                                  {quote?.created}
                                </Moment>
                              </div>
                            </div>
                          </td>
                          <td style={{paddingRight: '45px'}}>
                            <div className='fs-6'>
                              <div className='fw-bolder mt-5'>Created By</div>
                              <div className='text-gray-600'>
                                {quote?.createdBy?.name} {quote?.createdBy?.surname}
                              </div>
                            </div>
                          </td>
                          <td style={{paddingRight: '45px'}}>
                            <div className='fs-6'>
                              <div className='fw-bolder mt-5'>Processed On</div>
                              <div className='text-gray-600'>
                                {quote.processedOn && (
                                    <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                                      {quote?.processedOn}
                                    </Moment>
                                )}
                              </div>
                            </div>
                          </td>
                          <td style={{paddingRight: '45px'}}>
                            <div className='fs-6'>
                              <div className='fw-bolder mt-5'>Processed By</div>
                              <div className='text-gray-600'>
                                {quote?.processedBy?.name} {quote?.processedBy?.surname}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                      {quote.reasonDeclined && <table>
                        <td style={{paddingRight: '45px'}}>
                          <div className='fs-6'>
                            <div className='fw-bolder mt-5'>Reason declined</div>
                            <div className='text-gray-600'>{quote.reasonDeclined}</div>
                          </div>
                        </td>
                      </table>}
                      
            <table style={{width: '100%'}}>
              <td>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5' style={{display: 'flex'}}>
                    Breakdown{' '}
                    {fetchingBreakdowns && (
                        <div className='d-flex align-items-center'>
                                  <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                                </div>
                              )}
                            </div>
                            <table style={{width: '100%'}}>
                              <tr className='bg-light'>
                                <th className='fw-bolder'>Cost</th>
                                <th className='fw-bolder'>Item</th>
                                <th className='fw-bolder'>Description</th>
                              </tr>
                              {quoteBreakdowns &&
                                quoteBreakdowns.length > 0 &&
                                quoteBreakdowns.map((item: any) => (
                                  <tr>
                                    <td>
                                      <div className='fs-6 text-gray-600'>
                                        {toMoneyFormat(item.cost)}
                                      </div>
                                    </td>
                                    <td>
                                      <div className='fs-6 text-gray-600'>{item.item}</div>
                                    </td>
                                    <td>
                                      <div className='fs-6 text-gray-600'>{item.description}</div>
                                    </td>
                                  </tr>
                                ))}
                            </table>
                          </div>
                        </td>
                      </table>
                      {user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner && (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                            marginTop: '10px',
                          }}
                        >
                          <button
                            type='button'
                            onClick={() => {
                              setShowBreakdownModal(true)
                            }}
                            className='btn btn-light'
                          >
                            Breakdown
                          </button>{' '}
                        </div>
                      )}
                      {user?.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Client && (
                        <table>
                          <td style={{paddingRight: '45px'}}>
                            <div className='fs-6'>
                              <div className='fw-bolder mt-5'>Attachments</div>
                              <div className='text-gray-600'>
                                {selectedData.attachments &&
                                  selectedData.attachments.map((item: any) => (
                                    <>
                                      {item.fileName.match(/.(jpg|jpeg|png|gif)$/i) ? (
                                        <tr>
                                          <td>
                                            <div style={{marginBottom: '5px', textAlign: 'center'}}>
                                              <img
                                                src={item.uri}
                                                alt='Image'
                                                style={{maxHeight: '450px', maxWidth: '450px'}}
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      ) : (
                                        <tr>
                                          <td>
                                            <div
                                              className='input-group input-group-solid'
                                              style={{marginBottom: '5px', width: '745px'}}
                                            >
                                              <span style={{margin: 'auto'}}>
                                                <KTSVG
                                                  path='/media/icons/duotune/files/fil003.svg'
                                                  className='svg-icon svg-icon-2x svg-icon-primary me-1'
                                                />
                                              </span>
                                              <input
                                                readOnly
                                                type='text'
                                                className='form-control form-control-solid'
                                                value={item.name}
                                              />
                                              <span
                                                className='input-group-text'
                                                style={{cursor: 'pointer'}}
                                                onClick={() => {
                                                  const link = document.createElement('a')
                                                  link.href = item.uri
                                                  link.download = item.name
                                                  link.click()
                                                }}
                                              >
                                                <KTSVG
                                                  path='/media/icons/duotune/arrows/arr004.svg'
                                                  className='svg-icon-3 svg-icon-dark'
                                                />
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  ))}
                              </div>

                              <div className='text-gray-600'>
                                {!selectedData.attachments && 'no attachments'}
                              </div>
                            </div>
                          </td>
                        </table>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <button
                        type='button'
                        onClick={() => {
                          resetForm()
                          handleClose()
                        }}
                        className='btn btn-light'
                        data-bs-dismiss='modal'
                        id='rd_Quotes_modal_dismiss'
                      >
                        Close
                      </button>
                      {viewOnly && canApprove && !isFromChildWorkOrder && (
                        <button
                          type='submit'
                          id='rd_create_tenant_submit'
                          className='btn btn-lg btn-primary'
                          disabled={isSubmitting}
                        >
                          {!isSubmitting && <span className='indicator-label'>Save</span>}
                          {isSubmitting && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      )}
                    </ModalFooter>
                  </Form>
                </Modal>
              </>
            )}
            {mode != 'View' && (
              <Modal isOpen={show} onHide={() => {}} zIndex={1000}>
                <Form>
                  <ModalHeader closeButton>
                    {' '}
                    <h5 className='modal-title'>{mode} Quote</h5>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <span className='svg-icon svg-icon-2x'></span>
                    </div>
                  </ModalHeader>
                  <ModalBody>
                    {' '}
                    <div
                      className='scroll-y me-n7 pe-7'
                      id='kt_modal_add_customer_scroll'
                      data-kt-scroll='true'
                      style={{maxHeight: '800px'}}
                    >
                      <table
                        className='scroll-y table table-row-dashed gy-5'
                        id='kt_table_users_login_session'
                      >
                        <tbody className='fs-6 fw-bold text-gray-600'>
                          <tr>
                            <td>
                              <label className='required form-label'>Ref #</label>
                              <Field
                                name='referenceNumber'
                                placeholder=''
                                type='text'
                                className='form-control form-control-solid'
                                id='referenceNumber'
                                disabled={viewOnly}
                              />
                              {touched.referenceNumber && errors.referenceNumber && (
                                <div className='fv-plugins-message-container'>
                                  <span className='text-danger' role='alert'>
                                    {errors.referenceNumber}
                                  </span>
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className='required form-label'>
                                Activity Description: <i>(don't include costs $)</i>
                              </label>
                              <Field
                                name='description'
                                placeholder=''
                                className='form-control form-control-solid'
                                id='description'
                                rows='5'
                                component='textarea'
                                disabled={viewOnly}
                              />
                              {touched.description && errors.description && (
                                <div className='fv-plugins-message-container'>
                                  <span className='text-danger' role='alert'>
                                    {errors.description}
                                  </span>
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className='required form-label'>Quote (excl gst)</label>
                              <div className='input-group mb-5'>
                                <span className='input-group-text'>$</span>{' '}
                                <Field
                                  name='estimate'
                                  type='number'
                                  className='form-control form-control-solid'
                                  id='estimate'
                                  disabled={viewOnly}
                                  onChange={(e: any) => {
                                    var value = e.target.value
                                    value =
                                      value.indexOf('.') >= 0
                                        ? value.substr(0, value.indexOf('.')) +
                                          value.substr(value.indexOf('.'), 3)
                                        : value

                                    setFieldValue('estimate', value)
                                  }}
                                />
                              </div>
                              {touched.estimate && errors.estimate && (
                                <div className='fv-plugins-message-container'>
                                  <span className='text-danger' role='alert'>
                                    {errors.estimate}
                                  </span>
                                </div>
                              )}
                            </td>
                          </tr>
                          {mode == 'Edit' && (
                            <>
                              <tr>
                                <td colSpan={2}>
                                  <label className='required form-label'>Quote Status</label>
                                  <QuoteStatusSelector
                                    placeholder='Select Quote Status'
                                    defaultValue={values.quoteStatus}
                                    onChange={setFieldValue}
                                  />

                                  {touched.quoteStatusId && errors.quoteStatusId && (
                                    <div className='fv-plugins-message-container'>
                                      <span className='text-danger' role='alert'>
                                        {errors.quoteStatusId}
                                      </span>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            </>
                          )}
                          <br />
                          Attachments
                          {(mode === 'View' || mode === 'Edit') &&
                            selectedData.attachments &&
                            selectedData.attachments.map((item: any) => (
                              <>
                                {item.fileName.match(/.(jpg|jpeg|png|gif)$/i) ? (
                                  <tr>
                                    <td>
                                      <div style={{marginBottom: '5px', textAlign: 'center'}}>
                                        <img
                                          src={item.uri}
                                          alt='Image'
                                          style={{maxHeight: '450px', maxWidth: '450px'}}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ) : (
                                  <tr>
                                    <td>
                                      <div
                                        className='input-group input-group-solid'
                                        style={{marginBottom: '5px'}}
                                      >
                                        <span style={{margin: 'auto'}}>
                                          <KTSVG
                                            path='/media/icons/duotune/files/fil003.svg'
                                            className='svg-icon svg-icon-2x svg-icon-primary me-1'
                                          />
                                        </span>
                                        <input
                                          readOnly
                                          type='text'
                                          className='form-control form-control-solid'
                                          value={item.name}
                                        />
                                        <span
                                          className='input-group-text'
                                          style={{cursor: 'pointer'}}
                                          onClick={() => {
                                            const link = document.createElement('a')
                                            link.href = item.Uri
                                            link.download = item.name
                                            link.click()
                                          }}
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/arrows/arr004.svg'
                                            className='svg-icon-3 svg-icon-dark'
                                          />
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            ))}
                          {(mode === 'View' || mode === 'Edit') && !selectedData.attachments && (
                            <>No Attachments</>
                          )}
                          {mode === 'Add' && (
                            <>
                              <tr>
                                <td>
                                  <label className='form-label'>Quote File</label>
                                  <input
                                    type='file'
                                    className='form-control form-control-solid'
                                    id='file'
                                    name='file'
                                    disabled={viewOnly}
                                    onChange={(e) => {
                                      if (!e.target.files) return
                                      setFieldValue('file', e.target.files[0])
                                    }}
                                  />
                                  {touched.file && errors.file && (
                                    <div className='fv-plugins-message-container'>
                                      <span className='text-danger' role='alert'>
                                        {errors.file}
                                      </span>
                                    </div>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <label className='form-label'>File Name</label>
                                  <Field
                                    name='fileName'
                                    placeholder=''
                                    type='text'
                                    className='form-control form-control-solid'
                                    id='fileName'
                                    disabled={viewOnly || !values.file}
                                  />
                                  {touched.fileName && errors.fileName && (
                                    <div className='fv-plugins-message-container'>
                                      <span className='text-danger' role='alert'>
                                        {errors.fileName}
                                      </span>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    {' '}
                    <button
                      type='button'
                      onClick={() => {
                        resetForm()
                        handleClose()
                      }}
                      className='btn btn-light'
                      data-bs-dismiss='modal'
                      id='rd_Quotes_modal_dismiss'
                    >
                      Close
                    </button>
                    {!viewOnly && (
                      <button
                        type='submit'
                        id='rd_create_tenant_submit'
                        className='btn btn-lg btn-primary'
                        disabled={isSubmitting}
                      >
                        {!isSubmitting && <span className='indicator-label'>Save</span>}
                        {isSubmitting && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    )}
                  </ModalFooter>
                </Form>
              </Modal>
            )}
          </>
        )}
      </Formik>
    </>
  )
}

export default QuotesModal
