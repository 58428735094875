/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useHistory, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import Moment from 'react-moment'
import {QuotesClient} from '../../../infrastracture/api/QuotesClient'
import QuoteStatusSelector from '../../../common/components/selectors/QuoteStatusSelector'
import _ from 'lodash'
import {OverlayTrigger} from 'react-bootstrap-v5'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import * as auth from '../../auth/redux/AuthRedux'
import AttachmentsViewModal from '../../attachments/components/AttachmentsViewModal'
import {
  QuoteStatusClient,
  QuoteStatusQueryParams,
} from '../../../infrastracture/api/QuoteStatusClient'
import {ChargesClient, ChargesQueryParams} from '../../../infrastracture/api/ChargesClient'
import {toMoneyFormat} from '../../../common/helpers/stringHelper'
import {TenantTypeIds} from '../../../common/constants/tenantTypeIds'
import {WorkOrderClient, WorkOrderQueryParams} from '../../../infrastracture/api/WorkOrderClient'
import {TenantsClient} from '../../../infrastracture/api/TenantsClient'
import {getCharges} from '../../quotes/helper/quotesHelper'
import {QuoteStatus} from '../../quotes/constants/quoteStatus'
import {
  QuoteBreakdownClient,
  QuoteBreakdownQueryParams,
} from '../../../infrastracture/api/QuoteBreakdownClient'
import QuoteDeclineModal from "./QuoteDeclineModal";

export function QuoteApprovalForm() {
  const params: any = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const [loading, setLoading] = useState(false)
  const [invalidQuote, setInvalidQuote] = useState(false)
  const [quote, setQuote] = useState<any>({})
  const [charges, setCharges] = useState<any>([])
  const [approvedStatus, setApprovedStatus] = useState<any>({})
  const [declinedStatus, setDeclinedStatus] = useState<any>({})
  const [newStatus, setNewStatus] = useState<any>({})
  const [statuses, setStatuses] = useState<any>({})
  const [selectedData, setSelectedData] = useState<any>({})
  const [totalCost, setTotalCost] = useState(0)
  const [showDeclineModal, setShowDeclineModal] = useState(false)

  const [projectQuotes, setProjectQuotes] = useState<any>([])

  const [isMobile, setIsMobile] = useState(false)
  const [items, setItems] = useState<any>([])

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any
  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  useEffect(() => {
    //check screen width on load
    handleResize()
  }, [])

  useEffect(() => {
    if (params?.id) {
      fetchData()
      getQuoteBreakdowns(params?.id)
    }
  }, [params])

  useEffect(() => {
    if (!isAuthorized) {
      dispatch(auth.actions.logout())
      localStorage.removeItem('access-token-v2')
      localStorage.removeItem('refresh-token')
      localStorage.removeItem('api-domain')
      localStorage.clear()
      history.push(`/login?redirect=/quote-approval/${params?.id}`)
    }
  }, [isAuthorized])

  const getQuoteBreakdowns = async (quoteId: string) => {
    setLoading(true)
    var query = new QuoteBreakdownQueryParams()

    var response = await QuoteBreakdownClient.List(query.WithQuoteId(quoteId))
    if (!response.authenticated) {
    } else {
      const {data} = response

      setItems(data)
      setLoading(false)
    }
  }

  const getAllChargesAndTotalForProject = async (wo: any) => {
    var total = 0
    var charges: any[] = []
    var projectWos: any[] = []

    var parentWorkOrderId = wo.isParent ? wo.id : wo.workOrderId

    //only get charges from parent, no quotes form parent
    var chargesQuery = await ChargesClient.List(
      new ChargesQueryParams()
        .Paginate(0, 99999)
        .WithWorkOrderId(parentWorkOrderId)
        .Sort('id', 'asc')
    )
    if (chargesQuery?.data?.data && chargesQuery?.data?.data.length > 0) {
      for (let index = 0; index < chargesQuery?.data?.data.length; index++) {
        const charge = chargesQuery?.data?.data[index]
        charges.push({
          value: charge.value,
          chargeType: {name: charge.chargeType.name},
        })
        total += parseFloat(charge.value)
      }
    }

    //get child WOs
    var childrenWo = await WorkOrderClient.List(
      new WorkOrderQueryParams()
        .Paginate(0, 99999)
        .WithParentWorkOrderId(parentWorkOrderId)
        .Sort('id', 'asc')
    )

    //get calculation for children quotes
    if (childrenWo.data.data && childrenWo.data.data.length > 0) {
      for (let index = 0; index < childrenWo.data.data.length; index++) {
        const _childWO = childrenWo.data.data[index]

        console.log({childWO: _childWO})
        const _woRequest = await WorkOrderClient.Get(_childWO.id)
        const _wo = _woRequest.data

        const _tenantRequest = await TenantsClient.Get(_wo?.tenantId)

        if (_childWO.quotes && _childWO.quotes.length > 0) {
          for (let x = 0; x < _childWO.quotes.length; x++) {
            const quote = _childWO.quotes[x]
            var _response = await QuotesClient.Get(quote.id)
            const {data: _data} = _response

            var _quotesDetails = {..._data}

            if (_data?.quoteStatus) {
              const quoteStatus = _data.quoteStatus
              _quotesDetails = {
                ..._quotesDetails,
                quoteStatus: {value: quoteStatus.id, label: quoteStatus.name},
                quoteStatusId: quoteStatus.id,
              }

              //skip if quote status is not approved or submitted
              if (
                quoteStatus.name !== QuoteStatus.Submitted &&
                quoteStatus.name !== QuoteStatus.Approved
              )
                continue
              else {
                //if approved then check if is any submitted quote,
                if (quoteStatus.name === QuoteStatus.Approved) {
                  var exist = _.find(_childWO.quotes, function (n: any) {
                    return (
                      n.workOrderId === quote.workOrderId &&
                      n.id !== quote.id &&
                      n.quoteStatus.name === QuoteStatus.Submitted
                    )
                  })
                  // if yes ignore the approved quote
                  if (!!exist) continue
                }
              }
            }

            projectWos.push(_quotesDetails)

            if (_quotesDetails.quoteStatus.label === QuoteStatus.Approved) {
              var chargesQuery = await ChargesClient.List(
                new ChargesQueryParams()
                  .Paginate(0, 99999)
                  .WithWorkOrderId(quote.workOrderId)
                  .Sort('id', 'asc')
              )
              if (chargesQuery?.data?.data && chargesQuery?.data?.data.length > 0) {
                for (let index = 0; index < chargesQuery?.data?.data.length; index++) {
                  const charge = chargesQuery?.data?.data[index]
                  charges.push({
                    value: charge.value,
                    chargeType: {name: charge.chargeType.name},
                  })
                  total += parseFloat(charge.value)
                }
              }
            } else {
              var _mappedData = await getCharges(_quotesDetails, _wo, _tenantRequest.data, user)

              if (_mappedData?.charges && _mappedData?.charges.length > 0) {
                //if already approved, get active charges from DB

                charges.push(_mappedData?.charges)
                total += _mappedData.totalCost
              }
            }
          }
        }
      }
    }

    if (projectWos) setProjectQuotes(projectWos)

    return {total, charges}
  }

  const fetchData = async () => {
    setLoading(true)
    var response = await QuotesClient.Get(params.id)

    if (response.successful) {
      const {data} = response

      var quotesDetails = {...data}

      if (data?.quoteStatus) {
        const quoteStatus = data.quoteStatus
        quotesDetails = {
          ...quotesDetails,
          quoteStatus: {value: quoteStatus.id, label: quoteStatus.name},
          quoteStatusId: quoteStatus.id,
        }
      }

      //TODO: SUPPORT SEARCH FOR PERFORMANCE AGAINST LARGE COLLECTIONS
      var statuses = (
        await QuoteStatusClient.List(
          new QuoteStatusQueryParams().Paginate(0, 10000).Sort('name', 'asc').WithActive(true)
        )
      ).data

      setStatuses(statuses.data)

      const approvedStatus = _.find(statuses.data, {name: 'Approved'})
      const declinedStatus = _.find(statuses.data, {name: 'Declined'})

      const woRequest = await WorkOrderClient.Get(quotesDetails.workOrderId)
      const wo = woRequest.data

      const tenantRequest = await TenantsClient.Get(wo?.tenantId)

      var mappedData = await getCharges(quotesDetails, wo, tenantRequest.data, user)

      //if parent need to get all charges from the children WO
      if (wo.isParent || wo.workOrderId) {
        const {total, charges}: any = await getAllChargesAndTotalForProject(wo)
        setCharges(charges)
        setTotalCost(total)
      }

      //if  child, need to get all charges from related wo in project WO
      else {
        var mappedData: any = await getCharges(quotesDetails, wo, tenantRequest.data, user)

        setCharges(mappedData?.charges ?? [])
        setTotalCost(mappedData.totalCost)
      }

      setApprovedStatus(approvedStatus)
      setDeclinedStatus(declinedStatus)

      setQuote(mappedData)
      setInvalidQuote(false)
    } else {
      setInvalidQuote(true)
    }

    setLoading(false)
  }

  const renderModals = () => {
    return <AttachmentsViewModal selectedData={selectedData} />
  }

  return (
    <>
      {renderModals()}

      <QuoteDeclineModal
          handleOk={async function (reason: any) {

            setShowDeclineModal(false)
            setLoading(true)


            if (projectQuotes.length > 0) {
              var unique: any = _.uniq(projectQuotes)
              for (let index = 0; index < unique.length; index++) {
                const _quote = unique[index]
                await QuotesClient.Update(
                    _quote.id,
                    _quote.name,
                    _quote.description,
                    _quote.referenceNumber,
                    _quote.estimate,
                    _quote.quoteStatusId
                )
                await QuotesClient.Decline(_quote.id,
                    declinedStatus.id,
                    reason);
              }
            } else {
              await QuotesClient.Update(
                  quote.id,
                  quote.name,
                  quote.description,
                  quote.referenceNumber,
                  quote.estimate,
                  quote.quoteStatusId
              )
              await QuotesClient.Decline(quote.id,
                  declinedStatus.id,
                  reason);
            }
            
            setLoading(false)
            await fetchData()

          }}
          handleClose={function (): void {
            setShowDeclineModal(false)
          }}
          show={showDeclineModal}
      />
      
      <div
        className='bg-white rounded shadow-sm mx-auto'
        style={isMobile ? {} : {width: '600px', margin: '20px 50px'}}
      >
        <div className={`card`}>
          <div className='card-header'>
            <div className='card-title'>
              Quote Approval
              {loading && (
                <div className='d-flex align-items-center'>
                  <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                </div>
              )}
            </div>

            <div className='card-toolbar'>{quote?.quoteStatus?.label}</div>
          </div>
          <div className='card-body py-3'>
            <table width='100%'>
              <tr>
                <td colSpan={2}>
                  <td>
                    <div className='fs-6'>
                      <div className='fw-bolder mt-5'>Client</div>
                      <div className='text-gray-600'>{quote?.workOrder?.tenant?.name}</div>
                    </div>
                  </td>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='fs-6'>
                    <div className='fw-bolder mt-5'>QO #</div>
                    <div className='text-gray-600'>QO-{quote?.quoteNumberId}</div>
                  </div>
                </td>
                <td>
                  <div className='fs-6'>
                    <div className='fw-bolder mt-5'>WO #</div>
                    <div className='text-gray-600'>{quote?.workOrder?.workOrderNumber}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='fs-6'>
                    <div className='fw-bolder mt-5'>Ref #</div>
                    <div className='text-gray-600'>{quote?.referenceNumber}</div>
                  </div>
                </td>

                <td>
                  <div className='fs-6'>
                    <div className='fw-bolder mt-5'>Site</div>
                    <div className='text-gray-600'>{quote?.workOrder?.site?.name}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div>
                    <div className='fw-bolder mt-5 fs-6'>Total incl Fees</div>
                    <div className='text-dark-600 fw-bolder fs-5'>{toMoneyFormat(totalCost)}</div>
                  </div>
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <div className='fs-6'>
                    <div className='fw-bolder mt-5'>Description</div>
                    <div
                      className='text-gray-600'
                      style={{
                        background: '#eaeaea',
                        borderRadius: '5px',
                        padding: '5px',
                        minHeight: '100px',
                      }}
                    >
                      {quote?.description}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className='fw-bolder mt-5' style={{display: 'flex'}}>
                    Breakdown{' '}
                    {loading && (
                      <div className='d-flex align-items-center'>
                        <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                      </div>
                    )}
                  </div>

                  <table style={{width: '100%'}}>
                    <td>
                      <div className='fs-6'>
                        <table style={{width: '100%'}}>
                          <tr className='bg-light'>
                            <th className='fw-bolder'>Cost</th>
                            <th className='fw-bolder'>Item</th>
                            <th className='fw-bolder'>Description</th>
                          </tr>
                          {items &&
                            items.length > 0 &&
                            items.map((item: any) => (
                              <tr>
                                <td>
                                  <div className='fs-6 text-gray-600'>
                                    {toMoneyFormat(item.cost)}
                                  </div>
                                </td>
                                <td>
                                  <div className='fs-6 text-gray-600'>{item.item}</div>
                                </td>
                                <td>
                                  <div className='fs-6 text-gray-600'>{item.description}</div>
                                </td>
                              </tr>
                            ))}
                        </table>
                      </div>
                    </td>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='fs-6'>
                    <div className='fw-bolder mt-5'>Created By</div>
                    <div className='text-gray-600'>
                      {quote?.createdBy?.name} {quote?.createdBy?.surname}
                    </div>
                  </div>
                </td>
                <td colSpan={2}>
                  <div className='fs-6'>
                    <div className='fw-bolder mt-5'>Created On</div>
                    <div className='text-gray-600'>
                      <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                        {quote?.created}
                      </Moment>
                    </div>
                  </div>
                </td>
              </tr>
              {quote && quote.reasonDeclined && <tr>
                <td>
                  <div className='fs-6'>
                    <div className='fw-bolder mt-5'>Reason declined</div>
                    <div className='text-gray-600'>
                      {quote.reasonDeclined}
                    </div>
                  </div>
                  
                </td>
              </tr>}
              <tr>
                <td colSpan={2} style={{paddingTop: '20px'}}>
                  <h1 style={{color: 'rgb(145 145 145)'}}>WO Details</h1>
                </td>
              </tr>
              <tr>
                <td>
                <div className='fs-6'>
                    <div className='fw-bolder mt-5'>Type</div>
                    <div className='text-gray-600'>{quote?.workOrder?.workOrderType?.name}</div>
                  </div>
                </td>
                <td>
                  <div className='fs-6'>
                    <div className='fw-bolder mt-5'>Category</div>
                    <div className='text-gray-600'>{quote?.workOrder?.workOrderCategory?.name}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <div className='fs-6'>
                    <div className='fw-bolder mt-5'>Description</div>
                    <div
                      className='text-gray-600'
                      style={{
                        background: '#eaeaea',
                        borderRadius: '5px',
                        padding: '5px',
                        minHeight: '100px',
                      }}
                    >
                      {quote?.workOrder?.description}
                    </div>
                  </div>
                </td>
              </tr>
              {user?.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Client &&
                quote.attachments &&
                quote.attachments.length > 0 && (
                  <tr>
                    <td colSpan={3}>
                      <div className='fs-6'>
                        <div className='fw-bolder mt-5'>
                          Attachments ({quote.attachments.length}) - see below
                        </div>
                        <div>
                          {quote.attachments &&
                            quote.attachments.map((item: any) => (
                              <img
                                src={item.uri}
                                alt='Image'
                                style={{maxHeight: '540px', maxWidth: '540px'}}
                              />
                            ))}
                        </div>

                        <div className='text-gray-600'>
                          {!quote.attachments && 'no attachments'}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
            </table>
          </div>

          <div className='card-footer'>
            {quote?.quoteStatus?.label !== 'Approved' &&
              quote?.quoteStatus?.label !== 'Declined' &&
              quote?.quoteStatus?.label !== 'Cancelled' && (
                <div className='card-toolbar' style={{display: 'flex', justifyContent: 'end'}}>
                  {!invalidQuote && !_.isEmpty(declinedStatus) && (
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary'
                      style={{marginRight: '3px'}}
                      disabled={loading}
                      onClick={async () => {
                        setShowDeclineModal(true)
                      }}
                    >
                      Decline
                    </button>
                  )}
                  {!invalidQuote && !_.isEmpty(approvedStatus) && (
                    <button
                      type='button'
                      className='btn btn-sm btn-primary'
                      disabled={loading}
                      onClick={async () => {
                        setLoading(true)
                        if (projectQuotes.length > 0) {
                          var unique: any = _.uniq(projectQuotes)
                          for (let index = 0; index < unique.length; index++) {
                            const _quote = unique[index]
                            await QuotesClient.Update(
                              _quote.id,
                              _quote.name,
                              _quote.description,
                              _quote.referenceNumber,
                              _quote.estimate,
                              approvedStatus.id
                            )
                          }
                        } else {
                          await QuotesClient.Update(
                            quote.id,
                            quote.name,
                            quote.description,
                            quote.referenceNumber,
                            quote.estimate,
                            approvedStatus.id
                          )
                        }

                        setLoading(false)
                        fetchData()
                      }}
                    >
                      Approve
                    </button>
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  )
}
