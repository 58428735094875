import React, {useState, useEffect} from 'react'
import {QuotesClient} from '../../../infrastracture/api/QuotesClient'
import QuoteStatusSelector from '../../../common/components/selectors/QuoteStatusSelector'

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import * as Yup from 'yup'
import {Formik, Form} from 'formik'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import {TenantTypeIds} from '../../../common/constants/tenantTypeIds'
import {toMoneyFormat} from '../../../common/helpers/stringHelper'
import _ from 'lodash'

interface Props {
    handleOk: () => void
    handleClose: () => void
    selectedData: any
    workOrderId: string
    show: boolean
    workOrderNumber: string
    viewOnly?: boolean
    defaultAmount?: any
}

const QuotesForParentWorkOrderModal: React.FC<Props> = (props) => {
    const {
        handleOk,
        selectedData,
        viewOnly,
        workOrderId,
        workOrderNumber,
        show,
        defaultAmount,
        handleClose,
    } = props

    const [loading, setLoading] = useState(false)
    const [quote, setQuote] = useState<any>({})
    const [mappedCharges, setMappedCharges] = useState<any>([])
    const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

    const validationSchema = Yup.object().shape({})

    const [initialValues, setInitialValues] = useState<any>({})

    useEffect(() => {
        if (defaultAmount && defaultAmount > 0) {
            setInitialValues({
                ...initialValues,
                estimate: defaultAmount,
            })
        }
    }, [defaultAmount])

    useEffect(() => {
        setLoading(true)

        if (selectedData) {
            let quotesDetails = {...selectedData}

            if (selectedData?.quoteStatus) {
                const quoteStatus = selectedData.quoteStatus
                quotesDetails = {
                    ...quotesDetails,
                    quoteStatus: {value: quoteStatus.id, label: quoteStatus.name},
                    quoteStatusId: quoteStatus.id,
                }
            }

            setInitialValues(quotesDetails)
            setQuote(selectedData)

            if (selectedData.charges && selectedData.charges.length > 0) {
                let parentCharges = selectedData.charges.filter(
                    (x: any) => x.workOrderNumber === workOrderNumber
                )

                let childCharges = selectedData.charges.filter(
                    (x: any) => x.workOrderNumber !== workOrderNumber
                )

                if (childCharges && childCharges.length > 0) {
                    let grouped = _(childCharges)
                        .groupBy('workOrderNumber')
                        .map(function (items, workOrderNumber) {
                            return {
                                workOrderNumber: workOrderNumber,
                                items: items,
                            }
                        })
                        .value()

                    let mapped = grouped.map((charge) => {
                        return {
                            workOrderNumber: charge.workOrderNumber,
                            value: _.sumBy(charge.items, 'value'),
                        }
                    })
                    setMappedCharges([...parentCharges, ...mapped])
                } else {
                    setMappedCharges(parentCharges)
                }
            }
        }

        setLoading(false)
    }, [selectedData])

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {
                    let response: any

                    if (quote?.submittedQuotes && quote?.submittedQuotes.length > 0) {
                        for (let index = 0; index < quote?.submittedQuotes.length; index++) {
                            const _quote = quote?.submittedQuotes[index]
                            await QuotesClient.Update(
                                _quote.id,
                                _quote.name,
                                _quote.description,
                                _quote.referenceNumber,
                                _quote.estimate,
                                values.quoteStatusId
                            )
                        }
                    }

                    setSubmitting(false)
                    handleOk()
                }}
                enableReinitialize
            >
                {({errors, touched, isSubmitting, values, setFieldValue, resetForm}: any) => (
                    <>
                        <>
                            <Modal isOpen={show} onHide={() => {
                            }} zIndex={1000} size='lg'>
                                <Form>
                                    <ModalHeader closeButton>
                                        <h5 className='modal-title'>View Quote</h5>
                                    </ModalHeader>
                                    <ModalBody>
                                        {' '}
                                        <table>
                                            {(user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Client ||
                                                    user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner) &&
                                                quote?.quoteStatus?.name !== 'Approved' && (
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <label className='required form-label'>Quote Status</label>
                                                            <QuoteStatusSelector
                                                                placeholder='Select Quote Status'
                                                                defaultValue={values.quoteStatus}
                                                                onChange={setFieldValue}
                                                            />

                                                            {touched.quoteStatusId && errors.quoteStatusId && (
                                                                <div className='fv-plugins-message-container'>
                                  <span className='text-danger' role='alert'>
                                    {errors.quoteStatusId}
                                  </span>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                        </table>
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td style={{paddingRight: '152px', verticalAlign: 'top'}}>
                                                    <div className='fs-6'>
                                                        <div className='fw-bolder mt-5'>Amount</div>
                                                        <div className='text-gray-600'>${quote?.estimate}</div>
                                                    </div>
                                                </td>
                                                {user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner && (
                                                    <td>
                                                        <div className='fs-6'>
                                                            <div className='fw-bolder mt-5'>Breakdown</div>
                                                            {mappedCharges && mappedCharges.length > 0 && (
                                                                <>
                                                                    <ul>
                                                                        {mappedCharges.map((charge: any) => {
                                                                            return (
                                                                                <li>
                                          <span className='text-dark fw-bolder'>
                                            WO-{charge.workOrderNumber}:{' '}
                                          </span>
                                                                                    <span className='text-gray-600'>
                                            {toMoneyFormat(charge.value)}

                                                                                        {charge.chargeType?.name && (
                                                                                            <span> - {charge?.chargeType?.name}</span>
                                                                                        )}
                                          </span>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                )}
                                            </tr>
                                            {user.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Contractor && (
                                                <tr>
                                                    <td colSpan={2}>
                                                        <div>
                                                            <div className='fw-bolder mt-5 fs-6'>Total</div>
                                                            <div className='text-dark-600 fw-bolder fs-5'>
                                                                {toMoneyFormat(quote?.estimate)}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button
                                            type='button'
                                            onClick={() => {
                                                resetForm()
                                                handleClose()
                                            }}
                                            className='btn btn-light'
                                            data-bs-dismiss='modal'
                                            id='rd_Quotes_modal_dismiss'
                                        >
                                            Close
                                        </button>
                                        {(user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Client || user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner) &&
                                            quote?.quoteStatus?.name !== 'Approved' && (
                                                <button
                                                    type='submit'
                                                    id='rd_create_tenant_submit'
                                                    className='btn btn-lg btn-primary'
                                                    disabled={isSubmitting}
                                                >
                                                    {!isSubmitting && <span className='indicator-label'>Save</span>}
                                                    {isSubmitting && (
                                                        <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                                                    )}
                                                </button>
                                            )}
                                    </ModalFooter>
                                </Form>
                            </Modal>
                        </>
                    </>
                )}
            </Formik>
        </>
    )
}

export default QuotesForParentWorkOrderModal
