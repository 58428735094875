import {ApiClient} from '../../../setup/axios/SetupAxios'
import {ListQueryParams} from '../ApiClient'
import {GetAPIDomain} from '../Helper'

export class CheckListItemFileQueryParams extends ListQueryParams {
    WithWorkOrderId(value: any) {
        this.WithParam('workOrderId', value)
        return this
    }
}

export class CheckListItemFileClient {
    static async List(params: CheckListItemFileQueryParams) {
        return await ApiClient.Get('/checklist-item-file' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/checklist-item-file/' + id);
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/checklist-item-file/' + id);
    }

    static async Create(checkListItemId: string, name: string, file: any, onUploadProgress: any) {
        let formData = new FormData()
        formData.append('name', name)
        formData.append('checkListItemId', checkListItemId)
        formData.append('file', file)

        let accessToken = localStorage.getItem('access-token-v2')

        return await fetch((await GetAPIDomain()) + '/checklist-item-file', {
            method: 'post',
            headers: new Headers({Authorization: 'Bearer ' + accessToken}),
            body: formData,
        })
    }

    static async Update(id: string, name: string) {
        return await ApiClient.Patch('/checklist-item-file/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
        ]);
    }
}
