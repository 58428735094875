import React, { useState} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {TableHeader} from '../../../common/components/TableHeader'
import Pagination from 'react-responsive-pagination'
import RecordPerPageSelector from '../../../common/components/selectors/RecordPerPageSelector'
import Moment from 'react-moment'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {TenantTypeIds} from '../../../common/constants/tenantTypeIds'
import {toMoneyFormat} from '../../../common/helpers/stringHelper'

interface Props {
  parentWorkOrderId: string
  childrenWorkOrders: any[]
}

const WorkOrderChildren: React.FC<Props> = (props) => {
  const {childrenWorkOrders, parentWorkOrderId} = props

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  const [search, setSearch] = useState('')
  const [active, setActive] = useState(true)
  const [selectedData, setSelectedData] = useState<any>({})
  const [mode, setMode] = useState('')
  const [viewOnly, setViewOnly] = useState(false)

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalRecords: 1,
    totalPages: 1,
    skip: 0,
    take: 50,
    reset: true,
    sort: 'name',
    direction: 'asc',
  })

  const [loading, setLoading] = useState(false)

  return (
    <>
      <div className={`card`}>
        <div className='card-header'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <h2 className='text-gray-600'>Work Order Project</h2>
              {loading && (
                <div className='d-flex align-items-center'>
                  <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                </div>
              )}
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              {user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner && (
                <Link
                  className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                  to={`/work-orders/add?parentId=${parentWorkOrderId}`}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                  Add
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <TableHeader
                sort={pagination.sort}
                direction={pagination.direction}
                columns={[
                  {name: 'WO #', static: true},
                  {name: 'Site', static: true},
                  {name: 'Type', static: true},
                  {name: 'Category', static: true},
                  {name: 'Created', static: true},
                  {name: 'Created By', static: true},
                  {name: 'Cost', static: true},
                  {name: '', sort: '', static: true},
                ]}
                onUpdate={(sort: any, direction: any) => {
                  setPagination({
                    ...pagination,
                    sort,
                    direction,
                    reset: true,
                  })
                }}
              />
              {/* end::Table head */}
              {/* begin::Table body */}

              {!loading && (
                <tbody>
                  {!loading &&
                    childrenWorkOrders.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <Link
                            className='text-dark fw-bolder mb-1 fs-6'
                            to={`/work-orders/details/` + item.id}
                          >
                            {item.workOrderNumber && `${item?.workOrderNumber}`}

                            {!item.workOrderNumber && <i>Not specified</i>}
                          </Link>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder mb-1 fs-6'>{item.site?.name}</span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder mb-1 fs-6'>
                            {item.workOrderType?.name}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder mb-1 fs-6'>
                            {item.workOrderCategory?.name}
                          </span>
                        </td>

                        <td>
                          {item.created && (
                            <a className='text-dark fw-bolder mb-1 fs-6'>
                              <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                                {item.created}
                              </Moment>
                            </a>
                          )}
                          {!item.created && <i>Not specified</i>}
                        </td>
                        <td>
                          <span className='text-dark fw-bolder mb-1 fs-6'>
                            {item.createdBy?.name} {item.createdBy?.surname}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder mb-1 fs-6'>
                            {item.cost ? toMoneyFormat(item.cost) : '$'}
                          </span>
                        </td>
                        <td className='text-end'>
                          <Link
                            className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                            to={`/work-orders/details/` + item.id}
                          >
                            Details
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>

          <div className='card-footer px-1 py-4'>
            <div className='d-flex justify-content-between'>
              <div style={{minWidth: '200px'}}>
                <RecordPerPageSelector
                  placeholder='Rows per page'
                  onChange={(name: any, value: any) => {
                    setPagination({
                      ...pagination,
                      take: value,
                      reset: true,
                      currentPage: 1,
                    })
                  }}
                />
              </div>
              <div data-kt-notice-type-table-toolbar='base'>
                <Pagination
                  current={pagination.currentPage}
                  total={pagination.totalPages}
                  onPageChange={(page: any) => {
                    setPagination({
                      ...pagination,
                      currentPage: page,
                      skip: page === 1 ? 0 : (page - 1) * pagination.take,
                      reset: true,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkOrderChildren
