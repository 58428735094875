import axios from 'axios'
import { ApiClient } from '../../../../setup/axios/SetupAxios'
import { AuthModel } from '../models/AuthModel'


const API_URL = process.env.REACT_APP_API_URL || 'api'
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`

export async function GetAPIDomain() {

    let domain = localStorage.getItem('api-domain')?.replace(/\/$/, '')

    if (!domain) {
        const response = await fetch('/app-settings/domains')
        const data = await response.json()

        domain = data.domainApi
        if (domain) localStorage.setItem('api-domain', domain)
    }

    return domain
}

export async function login(username: string, password: string) {
    let formData = new URLSearchParams()
    formData.append('username', username)
    formData.append('password', password)
    formData.append('client_id', '4d2c5652-c3e0-443f-b317-ed1ba44a86bc')
    formData.append('grant_type', 'password')

    let domain = await GetAPIDomain()

    const response = await fetch(domain + '/connect/token', {
        body: formData,
        method: 'post',
    })

    const data = await response.json()

    if (response.ok) {
        localStorage.setItem('api-domain', domain || '')
        localStorage.setItem('access-token-v2', data.access_token)
        localStorage.setItem('refresh-token', data.refresh_token)
        localStorage.setItem('expires', new Date(new Date().getTime() + 50 * 60 * 1000).toISOString())
        return true
    }

    localStorage.clear()
    return false
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
    return axios.post<AuthModel>(REGISTER_URL, {
        email,
        firstname,
        lastname,
        password,
    })
}

export function LogOut() {
    localStorage.removeItem('access-token-v2')
    localStorage.removeItem('refresh-token')
    localStorage.removeItem('is-on-web')

    localStorage.clear()
}

export function IsAuthenticated() {
    if (localStorage.getItem('access-token-v2') == null) return false

    let token = localStorage.getItem('access-token-v2')
    return !(token != null && token.length === 0);
}

let refreshPromise: Promise<any> | null = null

async function RefreshTokenWithApi() {

    let token = localStorage.getItem('refresh-token')

    if (!token) {
        localStorage.clear()
        return
    }

    let formData = new URLSearchParams()
    formData.append('refresh_token', token)
    formData.append('client_id', '4d2c5652-c3e0-443f-b317-ed1ba44a86bc')
    formData.append('grant_type', 'refresh_token')

    const response = await fetch((await GetAPIDomain()) + '/connect/token', {
        body: formData,
        method: 'post',
    })

    if (!response.ok) {
        localStorage.clear()
        return false
    }

    const data = await response.json()

    localStorage.setItem('access-token-v2', data.access_token)
    localStorage.setItem('refresh-token', data.refresh_token)
    localStorage.setItem('expires', new Date(new Date().getTime() + 50 * 60 * 1000).toISOString())

    return true
}

export async function RefreshToken() {


    if (refreshPromise != null) {
        return await refreshPromise;
    }

    refreshPromise = RefreshTokenWithApi();

    refreshPromise.then(function () {
        refreshPromise = null;
    })

    return await refreshPromise;
   
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, { email })
}

export function getUserByToken() {
    return ApiClient.Get('/users/by-token')
}