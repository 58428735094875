/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { TenantTypeIds } from '../../../../app/common/constants/tenantTypeIds'
import { Permissions } from '../../../../app/common/constants/permissions'

export function AsideMenuMain() {
    const intl = useIntl()
    const permissions: any = useSelector<RootState>(
        ({ auth }) => auth.userPermission,
        shallowEqual
    ) as any

    const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any
    const isOwner = user.tenant.tenantTypeId.toUpperCase() === TenantTypeIds.Owner
    return (
        <>
            {permissions?.includes(Permissions.VIEW_MENU_DASHBOARD) && (
                <AsideMenuItem
                    to='/dashboard'
                    icon='/media/icons/duotune/art/art002.svg'
                    title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
                    fontIcon='bi-app-indicator'
                />
            )}

            {(permissions?.includes(Permissions.VIEW_MENU_PROPOSAL)) && (<div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>RD Connect</span>
                </div>
            </div>)}

            {permissions?.includes(Permissions.VIEW_MENU_PROPOSAL) && (
                <AsideMenuItem
                    to='/proposals'
                    title='Proposals'
                    icon='/media/icons/duotune/general/gen024.svg'
                    fontIcon='bi-person'
                ></AsideMenuItem>
            )}

            {(permissions?.includes(Permissions.VIEW_MENU_CALENDAR) ||
                permissions?.includes(Permissions.VIEW_MENU_WORKORDERS) ||
                permissions?.includes(Permissions.VIEW_MENU_MESSAGES) ||
                permissions?.includes(Permissions.VIEW_MENU_CHECKINS) ||
                permissions?.includes(Permissions.VIEW_MENU_LIBRARY) ||
                permissions?.includes(Permissions.VIEW_MENU_SITES) ||
                permissions?.includes(Permissions.VIEW_MENU_USERS) ||
                permissions?.includes(Permissions.VIEW_MENU_NOTICES) ||
                permissions?.includes(Permissions.VIEW_MENU_INVITATIONS) ||
                permissions?.includes(Permissions.VIEW_MENU_HAZARD) ||
                permissions?.includes(Permissions.VIEW_MENU_INCIDENT) ||
                permissions?.includes(Permissions.VIEW_MENU_CLIENTS) ||
                permissions?.includes(Permissions.VIEW_MENU_CONTRACTORS) ||
                permissions?.includes(Permissions.FM_MODULE_MENU) ||
                permissions?.includes(Permissions.VIEW_MENU_ASSETS) ||
                permissions?.includes(Permissions.VIEW_MENU_CONTRACTS) ||
                permissions?.includes(Permissions.VIEW_MENU_SCHEDULES) ||
                permissions?.includes(Permissions.VIEW_MENU_ASSET_CATEGORIES) ||
                permissions?.includes(Permissions.VIEW_MENU_CONTRACT_CATEGORIES)) && (
                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>RD Maintain</span>
                        </div>
                    </div>
                )}

            {permissions?.includes(Permissions.VIEW_MENU_CALENDAR) && (
                <AsideMenuItem
                    to='/calendars'
                    icon='/media/icons/duotune/art/art002.svg'
                    title='Calendar'
                    fontIcon='bi-app-indicator'
                />
            )}
            {permissions?.includes(Permissions.VIEW_MENU_WORKORDERS) && (
                <AsideMenuItem
                    to='/work-orders'
                    title='Work Orders'
                    icon='/media/icons/duotune/technology/teh005.svg'
                    fontIcon='bi-person'
                ></AsideMenuItem>
            )}

            {permissions?.includes(Permissions.VIEW_MENU_MESSAGES) && (
                <AsideMenuItem
                    to='/messages'
                    title='Messages'
                    icon='/media/icons/duotune/technology/teh005.svg'
                    fontIcon='bi-person'
                ></AsideMenuItem>
            )}
            {permissions?.includes(Permissions.VIEW_MENU_CHECKINS) && (
                <AsideMenuItem
                    to='/check-ins'
                    title='Check Ins'
                    icon='/media/icons/duotune/general/gen013.svg'
                    fontIcon='bi-person'
                ></AsideMenuItem>
            )}

            {permissions?.includes(Permissions.VIEW_MENU_LIBRARY) && (
                <AsideMenuItem
                    to='/library'
                    title='Library'
                    icon='/media/icons/duotune/communication/com005.svg'
                    fontIcon='bi-person'
                ></AsideMenuItem>
            )}

            {permissions?.includes(Permissions.VIEW_MENU_SITES) && (
                <AsideMenuItem
                    to='/sites'
                    title='Sites'
                    icon='/media/icons/duotune/art/art002.svg'
                    fontIcon='bi-app-indicator'
                ></AsideMenuItem>
            )}

            {permissions?.includes(Permissions.VIEW_MENU_SITES) && (
                <AsideMenuItem
                    to='/locations'
                    title='Locations'
                    icon='/media/icons/duotune/art/art002.svg'
                    fontIcon='bi-app-indicator'
                ></AsideMenuItem>
            )}
            
            {permissions?.includes(Permissions.VIEW_MENU_USERS) && (
                <AsideMenuItem
                    to='/users'
                    title='Users'
                    icon='/media/icons/duotune/communication/com006.svg'
                    fontIcon='bi-person'
                ></AsideMenuItem>
            )}

            {permissions?.includes(Permissions.VIEW_MENU_NOTICES) && (
                <AsideMenuItem
                    to='/notices'
                    icon='/media/icons/duotune/communication/com004.svg'
                    title='Notices'
                    fontIcon='bi-app-indicator'
                />
            )}

            {permissions?.includes(Permissions.VIEW_MENU_INVITATIONS) && (
                <AsideMenuItem
                    to='/Invites'
                    icon='/media/icons/duotune/art/art002.svg'
                    title='Invitations'
                    fontIcon='bi-app-indicator'
                />
            )}

            {(permissions?.includes(Permissions.VIEW_MENU_HAZARD) ||
                permissions?.includes(Permissions.VIEW_MENU_INCIDENT)) && (
                    <AsideMenuItemWithSub
                        to='/hazards'
                        title='Health & Safety'
                        icon='/media/icons/duotune/general/gen011.svg'
                        fontIcon='bi-person'
                        children={
                            <>
                                {permissions?.includes(Permissions.VIEW_MENU_HAZARD) && (
                                    <AsideMenuItem
                                        to='/hazards/list'
                                        title='Hazards'
                                        hasBullet
                                        fontIcon='bi-person'
                                    ></AsideMenuItem>
                                )}

                                {permissions?.includes(Permissions.VIEW_MENU_INCIDENT) && (
                                    <AsideMenuItem
                                        to='/incidents/list'
                                        title='Incidents'
                                        hasBullet
                                        fontIcon='bi-person'
                                    ></AsideMenuItem>
                                )}
                            </>
                        }
                    />
                )}

            {(permissions?.includes(Permissions.VIEW_MENU_CLIENTS) ||
                permissions?.includes(Permissions.VIEW_MENU_CONTRACTORS)) && (
                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Partners</span>
                        </div>
                    </div>
                )}

            {permissions?.includes(Permissions.VIEW_MENU_CLIENTS) && (
                <AsideMenuItem
                    to='/clients'
                    icon='/media/icons/duotune/general/gen024.svg'
                    title='Clients'
                    fontIcon='bi-app-indicator'
                />
            )}

            {permissions?.includes(Permissions.VIEW_MENU_CONTRACTORS) && (
                <AsideMenuItem
                    to='/contractors'
                    icon='/media/icons/duotune/general/gen024.svg'
                    title='Contractors'
                    fontIcon='bi-app-indicator'
                />
            )}

            {permissions?.includes(Permissions.FM_MODULE_MENU) && (
                <AsideMenuItemWithSub
                    to='/fm-module'
                    title='FM Module'
                    icon='/media/icons/duotune/general/gen008.svg'
                    fontIcon='bi-person'
                    children={
                        <>
                            {permissions?.includes(Permissions.VIEW_MENU_ASSETS) && (
                                <AsideMenuItem
                                    to='/assets/list'
                                    title='Assets'
                                    hasBullet
                                    fontIcon='bi-person'
                                ></AsideMenuItem>
                            )}
                            {permissions?.includes(Permissions.VIEW_MENU_CONTRACTS) && (
                                <AsideMenuItem
                                    to='/contracts/list'
                                    title='Contracts'
                                    hasBullet
                                    fontIcon='bi-person'
                                ></AsideMenuItem>
                            )}
                            {permissions?.includes(Permissions.VIEW_MENU_SCHEDULES) && (
                                <AsideMenuItem
                                    to='/schedules/list'
                                    title='Schedules'
                                    hasBullet
                                    fontIcon='bi-person'
                                ></AsideMenuItem>
                            )}
                            {permissions?.includes(Permissions.VIEW_MENU_ASSET_CATEGORIES) && (
                                <AsideMenuItem
                                    to='/asset-categories/list'
                                    title='Asset Categories'
                                    hasBullet
                                    fontIcon='bi-person'
                                ></AsideMenuItem>
                            )}
                            {permissions?.includes(Permissions.VIEW_MENU_CONTRACT_CATEGORIES) && (
                                <AsideMenuItem
                                    to='/contract-categories'
                                    title='Contract Categories'
                                    hasBullet
                                    fontIcon='bi-person'
                                ></AsideMenuItem>
                            )}
                        </>
                    }
                />
            )}


            {(permissions?.includes(Permissions.VIEW_MENU_PROPOSAL_SECTION) || permissions?.includes(Permissions.VIEW_MENU_RATECARDS)) && (<div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>RD Connect Settings</span>
                </div>
            </div>)}


            {permissions?.includes(Permissions.VIEW_MENU_PROPOSAL_SECTION) && (
                <AsideMenuItem
                    to='/proposal-sections'
                    title='Proposal Sections'
                    icon='/media/icons/duotune/files/fil001.svg'
                    fontIcon='bi-person'
                ></AsideMenuItem>
            )}

            {permissions?.includes(Permissions.VIEW_MENU_RATECARDS) &&
                user.tenant.tenantTypeId.toUpperCase() === TenantTypeIds.Owner && (
                    <AsideMenuItem
                        to='/rate-cards'
                        title='Rate Cards'
                        icon='/media/icons/duotune/files/fil001.svg'
                        fontIcon='bi-person'
                    ></AsideMenuItem>
                )}

{permissions?.includes(Permissions.VIEW_MENU_RATECARDS) &&
                user.tenant.tenantTypeId.toUpperCase() === TenantTypeIds.Owner && (
                    <AsideMenuItem
                        to='/rate-card-types'
                        title='Rate Card Types'
                        icon='/media/icons/duotune/files/fil001.svg'
                        fontIcon='bi-person'
                    ></AsideMenuItem>
                )}

            {permissions?.includes(Permissions.VIEW_MENU_INVOLVEMENTTYPES) &&
                user.tenant.tenantTypeId.toUpperCase() === TenantTypeIds.Owner && (
                    <AsideMenuItem
                        to='/involvement-types'
                        title='Involvement Types'
                        icon='/media/icons/duotune/files/fil001.svg'
                        fontIcon='bi-person'
                    ></AsideMenuItem>
                )}

            {permissions?.includes(Permissions.VIEW_MENU_EXCLUSIONS) &&
                user.tenant.tenantTypeId.toUpperCase() === TenantTypeIds.Owner && (
                    <AsideMenuItem
                        to='/exclusions'
                        title='Exclusions'
                        icon='/media/icons/duotune/files/fil001.svg'
                        fontIcon='bi-clipboard-check'
                    ></AsideMenuItem>
                )}

            {(permissions?.includes(Permissions.VIEW_MENU_ACCOUNT) ||
                permissions?.includes(Permissions.VIEW_MENU_ROLES) ||
                permissions?.includes(Permissions.VIEW_MENU_ACCOUNT_MAPPINGS) ||
                permissions?.includes(Permissions.VIEW_MENU_CHECKLIST_TEMPLATES) ||
                permissions?.includes(Permissions.VIEW_MENU_BUSINESS_TYPES) ||
                permissions?.includes(Permissions.VIEW_MENU_DOCUMENT_TYPES) ||
                permissions?.includes(Permissions.VIEW_MENU_SITE_TYPES) ||
                permissions?.includes(Permissions.VIEW_MENU_NOTICE_TYPES) ||
                permissions?.includes(Permissions.VIEW_MENU_LEAVE_SITE_REASON) ||
                permissions?.includes(Permissions.VIEW_MENU_WORK_ORDER_STATUS) ||
                permissions?.includes(Permissions.VIEW_MENU_STATUS_FLOW) ||
                permissions?.includes(Permissions.VIEW_MENU_QUOTE_STATUS) ||
                permissions?.includes(Permissions.VIEW_MENU_WORK_ORDER_TYPES) ||
                permissions?.includes(Permissions.VIEW_MENU_PRIORITIES) ||
                permissions?.includes(Permissions.VIEW_MENU_WORKSCOPE) ||
                permissions?.includes(Permissions.VIEW_MENU_CHARGE_TYPES) ||
                permissions?.includes(Permissions.VIEW_MENU_HAZARD_STATUS) ||
                permissions?.includes(Permissions.VIEW_MENU_HAZARD_STATUS) ||
                permissions?.includes(Permissions.VIEW_MENU_INCIDENT_STATUS) ||
                permissions?.includes(Permissions.VIEW_MENU_INCIDENT_TYPE) ||
                permissions?.includes(Permissions.VIEW_MENU_INCIDENT_CAUSE)) && (<div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>RD Maintain Settings</span>
                    </div>
                </div>)}

            {permissions?.includes(Permissions.VIEW_MENU_ACCOUNT) && (
                <AsideMenuItem
                    to='/account'
                    icon='/media/icons/duotune/art/art002.svg'
                    title='Account'
                    fontIcon='bi-app-indicator'
                />)}

            {permissions?.includes(Permissions.VIEW_MENU_ROLES) && (
                <AsideMenuItem
                    to='/roles'
                    title='Roles'
                    icon='/media/icons/duotune/files/fil001.svg'
                    fontIcon='bi-person'
                ></AsideMenuItem>
            )}

            {permissions?.includes(Permissions.VIEW_MENU_ACCOUNT_MAPPINGS) && (
                <AsideMenuItem
                    to='/tenant-mappings'
                    icon='/media/icons/duotune/art/art002.svg'
                    title='Account Mappings'
                    fontIcon='bi-app-indicator'
                />
            )}

            {permissions?.includes(Permissions.VIEW_MENU_CHECKLIST_TEMPLATES) && (
                <AsideMenuItem
                    to='/check-list-templates/list'
                    title='Check List Templates'
                    icon='/media/icons/duotune/general/gen043.svg'
                    fontIcon='bi-person'
                ></AsideMenuItem>
            )}

            <div data-kt-menu-trigger='click' className='menu-item menu-accordion hover show'>
                {(permissions?.includes(Permissions.VIEW_MENU_BUSINESS_TYPES) ||
                    permissions?.includes(Permissions.VIEW_MENU_DOCUMENT_TYPES) ||
                    permissions?.includes(Permissions.VIEW_MENU_SITE_TYPES) ||
                    permissions?.includes(Permissions.VIEW_MENU_NOTICE_TYPES) ||
                    permissions?.includes(Permissions.VIEW_MENU_LEAVE_SITE_REASON) ||
                    permissions?.includes(Permissions.VIEW_MENU_WORK_ORDER_STATUS) ||
                    permissions?.includes(Permissions.VIEW_MENU_STATUS_FLOW) ||
                    permissions?.includes(Permissions.VIEW_MENU_QUOTE_STATUS) ||
                    permissions?.includes(Permissions.VIEW_MENU_WORK_ORDER_TYPES) ||
                    permissions?.includes(Permissions.VIEW_MENU_PRIORITIES) ||
                    permissions?.includes(Permissions.VIEW_MENU_WORKSCOPE) ||
                    permissions?.includes(Permissions.VIEW_MENU_CHARGE_TYPES)) && (
                        <span className='menu-link'>
                            <span className='menu-icon'>
                                <span className='svg-icon svg-icon-2'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                    >
                                        <path
                                            opacity='0.3'
                                            d='M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z'
                                            fill='black'
                                        ></path>
                                        <rect x='6' y='12' width='7' height='2' rx='1' fill='black'></rect>
                                        <rect x='6' y='7' width='12' height='2' rx='1' fill='black'></rect>
                                    </svg>
                                </span>
                            </span>
                            <span className='menu-title'>Lists</span>
                            <span className='menu-arrow'></span>
                        </span>
                    )}
                <div className='menu-sub menu-sub-accordion show' kt-hidden-height='116' style={{}}>
                    {permissions?.includes(Permissions.VIEW_MENU_BUSINESS_TYPES) &&
                        user.tenant.tenantTypeId.toUpperCase() !== TenantTypeIds.Contractor && (
                            <div className='menu-item'>
                                <Link to='/business-types' className='menu-link'>
                                    <span className='menu-bullet'>
                                        <span className='bullet bullet-dot'></span>
                                    </span>
                                    <span className='menu-title'>Business Types</span>
                                </Link>
                            </div>
                        )}
                    {permissions?.includes(Permissions.VIEW_MENU_DOCUMENT_TYPES) && (
                        <div className='menu-item'>
                            <Link to='/document-types' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Document Types</span>
                            </Link>
                        </div>
                    )}
                    {permissions?.includes(Permissions.VIEW_MENU_BATCH_JOBS) && (
                        <div className='menu-item'>
                            <Link to='/batch-jobs' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Batch Jobs</span>
                            </Link>
                        </div>
                    )}
                    {permissions?.includes(Permissions.VIEW_MENU_SITE_TYPES) && (
                        <div className='menu-item'>
                            <Link to='/site-types' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Site Types</span>
                            </Link>
                        </div>
                    )}
                    {permissions?.includes(Permissions.VIEW_MENU_NOTICE_TYPES) && (
                        <div className='menu-item'>
                            <Link to='/notice-types' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Notice Types</span>
                            </Link>
                        </div>
                    )}
                    {permissions?.includes(Permissions.VIEW_MENU_LEAVE_SITE_REASON) && (
                        <div className='menu-item'>
                            <Link to='/leave-site-reason' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Leave Site Reason</span>
                            </Link>
                        </div>
                    )}
                    {permissions?.includes(Permissions.VIEW_MENU_WORK_ORDER_STATUS) && (
                        <div className='menu-item'>
                            <Link to='/work-order-status' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Work Order Status</span>
                            </Link>
                        </div>
                    )}
                    {permissions?.includes(Permissions.VIEW_MENU_STATUS_FLOW) && (
                        <div className='menu-item'>
                            <Link to='/status-flows' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Status Flow</span>
                            </Link>
                        </div>
                    )}
                    {permissions?.includes(Permissions.VIEW_MENU_QUOTE_STATUS) && (
                        <div className='menu-item'>
                            <Link to='/quote-status' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Quote Status</span>
                            </Link>
                        </div>
                    )}

                    {permissions?.includes(Permissions.VIEW_MENU_WORK_ORDER_TYPES) && (
                        <div className='menu-item'>
                            <Link to='/work-order-types' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Work Order Types</span>
                            </Link>
                        </div>
                    )}
                    {permissions?.includes(Permissions.VIEW_MENU_PRIORITIES) && (
                        <div className='menu-item'>
                            <Link to='/priorities' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Priorities</span>
                            </Link>
                        </div>
                    )}
                    {permissions?.includes(Permissions.VIEW_MENU_WORKSCOPE) && (
                        <div className='menu-item'>
                            <Link to='/work-scopes' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Work Scopes</span>
                            </Link>
                        </div>
                    )}
                    {/* <div className='menu-item'>
            <Link to='/category-work-scopes' className='menu-link'>
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>Category Work Scopes</span>
            </Link>
          </div> */}
                    {permissions?.includes(Permissions.VIEW_MENU_CHARGE_TYPES) && (
                        <div className='menu-item'>
                            <Link to='/charge-types' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Charge Types</span>
                            </Link>
                        </div>
                    )}
                </div>
            </div>

            <div data-kt-menu-trigger='click' className='menu-item menu-accordion hover show'>
                {(permissions?.includes(Permissions.VIEW_MENU_HAZARD_STATUS) ||
                    permissions?.includes(Permissions.VIEW_MENU_HAZARD_STATUS) ||
                    permissions?.includes(Permissions.VIEW_MENU_INCIDENT_STATUS) ||
                    permissions?.includes(Permissions.VIEW_MENU_INCIDENT_TYPE) ||
                    permissions?.includes(Permissions.VIEW_MENU_INCIDENT_CAUSE)) && (
                        <span className='menu-link'>
                            <span className='menu-icon'>
                                <span className='svg-icon svg-icon-2'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                    >
                                        <path
                                            opacity='0.3'
                                            d='M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z'
                                            fill='black'
                                        ></path>
                                        <rect x='6' y='12' width='7' height='2' rx='1' fill='black'></rect>
                                        <rect x='6' y='7' width='12' height='2' rx='1' fill='black'></rect>
                                    </svg>
                                </span>
                            </span>
                            <span className='menu-title'>H & S Settings</span>
                            <span className='menu-arrow'></span>
                        </span>
                    )}

                <div className='menu-sub menu-sub-accordion show' kt-hidden-height='116' style={{}}>
                    {permissions?.includes(Permissions.VIEW_MENU_HAZARD_STATUS) && (
                        <div className='menu-item'>
                            <Link to='/hazard-status' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Hazard Status</span>
                            </Link>
                        </div>
                    )}{' '}
                    {permissions?.includes(Permissions.VIEW_MENU_HAZARD_TYPE) && (
                        <div className='menu-item'>
                            <Link to='/hazard-type' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Hazard Type</span>
                            </Link>
                        </div>
                    )}{' '}
                    {permissions?.includes(Permissions.VIEW_MENU_INCIDENT_STATUS) && (
                        <div className='menu-item'>
                            <Link to='/incident-status' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Incident Status</span>
                            </Link>
                        </div>
                    )}{' '}
                    {permissions?.includes(Permissions.VIEW_MENU_INCIDENT_TYPE) && (
                        <div className='menu-item'>
                            <Link to='/incident-type' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Incident Type</span>
                            </Link>
                        </div>
                    )}{' '}
                    {permissions?.includes(Permissions.VIEW_MENU_INCIDENT_CAUSE) && (
                        <div className='menu-item'>
                            <Link to='/incident-cause' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Incident Cause</span>
                            </Link>
                        </div>
                    )}{' '}
                    {permissions?.includes(Permissions.VIEW_MENU_ACTION_STATUS) && (
                        <div className='menu-item'>
                            <Link to='/action-status' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Action Status</span>
                            </Link>
                        </div>
                    )}{' '}
                    {permissions?.includes(Permissions.VIEW_MENU_ACTION_TYPE) && (
                        <div className='menu-item'>
                            <Link to='/action-type' className='menu-link'>
                                <span className='menu-bullet'>
                                    <span className='bullet bullet-dot'></span>
                                </span>
                                <span className='menu-title'>Action Type</span>
                            </Link>
                        </div>
                    )}{' '}
                </div>
            </div>
        </>
    )
}
