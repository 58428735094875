import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'
import { GetAPIDomain } from '../Helper'

export class LibraryQueryParams extends ListQueryParams {
    WithStatus(value: any) {
        this.WithParam('status', value)
        return this
    }
    WithDocumentTypeId(value: any) {
        this.WithParam('documentTypeId', value)
        return this
    }
    WithWorkSafeOnly(value: boolean) {
        this.WithParam('workSafeOnly', value)
        return this
    }
    WithExcludeUploadedFromWorkOrderId(value: string) {
        this.WithParam('excludeUploadedFromWorkOrderId', value)
        return this
    }
    WithForUserId(value: string) {
        this.WithParam('forUserId', value)
        return this
    }
}

export class LibraryClient {
    static async List(params: LibraryQueryParams) {
        return await ApiClient.Get('/library' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/library/' + id);
    }

    static async GetTenants(id: string, tenantTypeId: string) {
        return await ApiClient.Get(`/library/${id}/tenants?tenantTypeId=${tenantTypeId}`);
    }

    static async AddTenant(id: string, tenantId: string) {

        return await ApiClient.Post('/library/tenants', {
            libraryId: id,
            tenantId: tenantId,
        });
    }

    static async RemoveTenant(id: string, tenantId: string) {
        return await ApiClient.Delete('/library/tenants', {
            libraryId: id,
            tenantId: tenantId,
        });
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/library/' + id);
    }

    static async Create(
        name: string,
        status: string,
        file: any,
        expireDate: string,
        documentTypeId: string
    ) {
        let formData = new FormData()
        formData.append('name', name)
        formData.append('status', status)
        formData.append('expireDate', expireDate)
        formData.append('documentTypeId', documentTypeId)
        formData.append('file', file)

        let accessToken = localStorage.getItem('access-token-v2')

        const response = await fetch((await GetAPIDomain()) + '/library', {
            method: 'post',
            headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
            body: formData,
        })

        return await response.json()
    }

    static async Update(id: string, name: string, status: any, expireDate: any, documentTypeID: any) {

        return await ApiClient.Patch('/library/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'status',
                value: status,
            },
            {
                op: 'replace',
                path: 'expireDate',
                value: expireDate,
            },
            {
                op: 'replace',
                path: 'documentTypeID',
                value: documentTypeID,
            },
        ]);
    }
}
