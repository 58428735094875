/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {QuotesClient} from '../../../infrastracture/api/QuotesClient'
import _ from 'lodash'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import * as auth from '../../auth/redux/AuthRedux'
import AttachmentsViewModal from '../../attachments/components/AttachmentsViewModal'
import {
    QuoteStatusClient,
    QuoteStatusQueryParams,
} from '../../../infrastracture/api/QuoteStatusClient'
import {ChargesClient, ChargesQueryParams} from '../../../infrastracture/api/ChargesClient'
import {toMoneyFormat} from '../../../common/helpers/stringHelper'
import {WorkOrderClient, WorkOrderQueryParams} from '../../../infrastracture/api/WorkOrderClient'
import {TenantsClient} from '../../../infrastracture/api/TenantsClient'
import {getCharges, getCurrentCharges, getProjectCharges} from '../../quotes/helper/quotesHelper'
import {QuoteStatus} from '../../quotes/constants/quoteStatus'
import QuoteDeclineModal from "./QuoteDeclineModal";

export function WorkOrderQuoteApprovalForm() {

    const params: any = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
    const [loading, setLoading] = useState(false)
    const [showDeclineModal, setShowDeclineModal] = useState(false)
    const [invalidQuote, setInvalidQuote] = useState(false)
    const [quotes, setQuotes] = useState<any[]>([])
    const [charges, setCharges] = useState<any>([])
    const [approvedStatus, setApprovedStatus] = useState<any>({})
    const [declinedStatus, setDeclinedStatus] = useState<any>({})
    const [adminCharges, setAdminCharges] = useState<any[]>([])
    const [statuses, setStatuses] = useState<any>({})
    const [selectedData, setSelectedData] = useState<any>({})
    const [totalCost, setTotalCost] = useState(0)
    const [workOrder, setWorkOrder] = useState<any>({})

    const [isMobile, setIsMobile] = useState(false)

    const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any
    //choose the screen size
    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    // create an event listener
    useEffect(() => {
        window.addEventListener('resize', handleResize)
    })

    useEffect(() => {
        //check screen width on load
        handleResize()
    }, [])

    useEffect(() => {
        if (params?.id)
            fetchData().then();
    }, [params])

    useEffect(() => {
        if (!isAuthorized) {
            dispatch(auth.actions.logout())
            localStorage.removeItem('access-token-v2')
            localStorage.removeItem('refresh-token')
            localStorage.removeItem('api-domain')
            localStorage.clear()
            history.push(`/login?redirect=/workorder-quote-approval/${params?.id}`)
        }
    }, [isAuthorized])

    const getAllChargesAndTotalForProject = async (wo: any) => {

        let total = 0
        let charges: any[] = []
        let projectWos: any[] = []

        let chargesToAdd: any[] = []

        let parentWorkOrderId = wo.isParent ? wo.id : wo.workOrderId

        //get child WOs
        let childrenWo = await WorkOrderClient.List(
            new WorkOrderQueryParams()
                .Paginate(0, 99999)
                .WithParentWorkOrderId(parentWorkOrderId)
                .Sort('id', 'asc')
        )

        //get calculation for children quotes
        if (childrenWo.data.data && childrenWo.data.data.length > 0) {
            for (let index = 0; index < childrenWo.data.data.length; index++) {
                const _childWO = childrenWo.data.data[index]

                console.log({childWO: _childWO})
                const _woRequest = await WorkOrderClient.Get(_childWO.id)
                const _wo = _woRequest.data

                const _tenantRequest = await TenantsClient.Get(_wo?.tenantId)

                if (_childWO.quotes && _childWO.quotes.length > 0) {
                    for (let x = 0; x < _childWO.quotes.length; x++) {
                        const quote = _childWO.quotes[x];

                        let qDetails = (await QuotesClient.Get(quote.id)).data;
                        let _data = await getCurrentCharges(qDetails, _childWO, _tenantRequest.data, user);

                        let _quotesDetails = {..._data}

                        if (_data?.quoteStatus) {
                            const quoteStatus = _data.quoteStatus
                            _quotesDetails = {
                                ..._quotesDetails,
                                quoteStatus: {value: quoteStatus.id, label: quoteStatus.name},
                                quoteStatusId: quoteStatus.id,
                            }

                            //skip if quote status is not approved or submitted
                            if (
                                quoteStatus.name !== QuoteStatus.Submitted &&
                                quoteStatus.name !== QuoteStatus.Approved
                            )
                                continue
                            else {
                                //if approved then check if is any submitted quote,
                                if (quoteStatus.name === QuoteStatus.Approved) {
                                    let exist = _.find(_childWO.quotes, function (n: any) {
                                        return (
                                            n.workOrderId === quote.workOrderId &&
                                            n.id !== quote.id &&
                                            n.quoteStatus.name === QuoteStatus.Submitted
                                        )
                                    })
                                    // if yes ignore the approved quote
                                    if (!!exist) continue
                                }
                            }
                        }

                        projectWos.push(_quotesDetails)

                        if (_quotesDetails.quoteStatus.label === QuoteStatus.Approved) {

                            let chargesQuery = await ChargesClient.List(
                                new ChargesQueryParams()
                                    .Paginate(0, 99999)
                                    .WithWorkOrderId(quote.workOrderId)
                                    .Sort('id', 'asc')
                            )

                            if (chargesQuery?.data?.data && chargesQuery?.data?.data.length > 0) {
                                for (let index = 0; index < chargesQuery?.data?.data.length; index++) {
                                    const charge = chargesQuery?.data?.data[index]
                                    charges.push({
                                        value: charge.value,
                                        chargeType: {name: charge.chargeType.name},
                                    })
                                    total += parseFloat(charge.value)
                                }
                            }
                        } else {
                            let _mappedData = await getCharges(_quotesDetails, _wo, _tenantRequest.data, user)

                            if (_mappedData?.charges && _mappedData?.charges.length > 0) {
                                //if already approved, get active charges from DB

                                charges.push(_mappedData?.charges)
                                total += _mappedData.totalCost
                            }
                        }
                    }
                }
            }
        }

        if (projectWos) setQuotes(projectWos)


        const _parentWO = await WorkOrderClient.Get(parentWorkOrderId)
        const _parentTenant = await TenantsClient.Get(_parentWO.data?.tenantId)

        console.log(total);
        let _charges = await getProjectCharges(total, _parentWO.data, _parentTenant.data);

        let shippingCharges = await ChargesClient.GetShippingChargesForProjectWorkOrder(parentWorkOrderId);
        const {data} = shippingCharges

        chargesToAdd = [..._charges, ...data];
        setAdminCharges(chargesToAdd);


        for (let index = 0; index < chargesToAdd.length; index++) {
            const charge = chargesToAdd[index]
            total += parseFloat(charge.value)
        }


        return {total, charges}
    }

    const fetchData = async () => {
        setLoading(true)
        let response = await WorkOrderClient.Get(params.id)

        if (response.successful) {

            setWorkOrder(response.data);

            //TODO: SUPPORT SEARCH FOR PERFORMANCE AGAINST LARGE COLLECTIONS
            let statuses = (
                await QuoteStatusClient.List(
                    new QuoteStatusQueryParams().Paginate(0, 10000).Sort('name', 'asc').WithActive(true)
                )
            ).data

            setStatuses(statuses.data)

            const approvedStatus = _.find(statuses.data, {name: 'Approved'})
            const declinedStatus = _.find(statuses.data, {name: 'Declined'})

            const wo = response.data

            //if parent need to get all charges from the children WO
            if (wo.isParent || wo.workOrderId) {
                const {total, charges}: any = await getAllChargesAndTotalForProject(wo)
                setCharges(charges)
                setTotalCost(total)
            }

            setApprovedStatus(approvedStatus)
            setDeclinedStatus(declinedStatus)

            setInvalidQuote(false)
        } else {
            setInvalidQuote(true)
        }

        setLoading(false)
    }

    const renderModals = () => {
        return <AttachmentsViewModal selectedData={selectedData}/>
    }

    return (
        <>
            {renderModals()}

            <QuoteDeclineModal
                handleOk={async function (reason: any) {

                    setLoading(true)
                    let unique: any = _.uniq(quotes.filter(q => q?.quoteStatus?.label === 'Submitted'))
                    for (let index = 0; index < unique.length; index++) {
                        const _quote = unique[index]
                        await QuotesClient.Update(
                            _quote.id,
                            _quote.name,
                            _quote.description,
                            _quote.referenceNumber,
                            _quote.estimate,
                            _quote.quoteStatusId
                        )

                        await QuotesClient.Decline(_quote.id,
                            declinedStatus.id,
                            reason);
                    }

                    setLoading(false)
                    await fetchData()

                    setShowDeclineModal(false)
                }}
                handleClose={function (): void {
                    setShowDeclineModal(false)
                }}
                show={showDeclineModal}
            />

            <div
                className='bg-white rounded shadow-sm mx-auto'
                style={isMobile ? {} : {width: '600px', margin: '20px 50px'}}
            >
                <div className={`card`}>
                    <div className='card-header'>
                        <div className='card-title'>
                            Quote Approval: {workOrder?.workOrderNumber}
                            {loading && (
                                <div className='d-flex align-items-center'>
                                    <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                                </div>
                            )}
                        </div>
                        <div>
                            {quotes.some(q => q.quoteChanged) &&
                                <i>Quote has been updated, different prices marked with *</i>}
                        </div>
                    </div>
                    <div className='card-body py-3'>
                        <table width='100%'>
                            <tbody>
                            <tr>
                                <td>
                                    <div className='fs-6'>
                                        <div className='fw-bolder mt-5'>Client</div>
                                        <div className='text-gray-600'>{workOrder?.tenant?.name}</div>
                                    </div>
                                </td>
                                <td>
                                    <div className='fs-6'>
                                        <div className='fw-bolder mt-5'>Site</div>
                                        <div className='text-gray-600'>{workOrder?.site?.name}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{paddingBottom: '15px'}}>
                                    <div className='fw-bolder mt-5'>Quotes</div>
                                    <div className='text-gray-600'>Details</div>
                                </td>
                                <td style={{paddingBottom: '15px'}}>
                                    <div className='fw-bolder mt-5'>&nbsp;</div>
                                    <div className='text-gray-600'>Estimates</div>
                                </td>
                            </tr>
                            <>
                                {quotes.map((quote) => (<tr>
                                    <td>
                                        <div className='fs-6'>
                                            <div className='text-gray-600'>QO-{quote?.quoteNumberId} - Ref
                                                #: {quote?.referenceNumber}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='fs-6'>
                                            <div
                                                className='text-gray-600'>{toMoneyFormat(quote.totalCost)}{quote?.quoteChanged && "*"}</div>
                                        </div>
                                    </td>
                                </tr>))}
                            </>
                            <tr>
                                <td style={{paddingBottom: '15px'}}>
                                    <div className='fw-bolder mt-5'>Fees</div>
                                    <div className='text-gray-600'>Details</div>
                                </td>
                                <td style={{paddingBottom: '15px'}}>
                                    <div className='fw-bolder mt-5'>&nbsp;</div>
                                    <div className='text-gray-600'>Amount</div>
                                </td>
                            </tr>

                            {adminCharges.map((charge) => (<tr>
                                <td>
                                    <div className='fs-6'>
                                        <div className='text-gray-600'>{charge?.chargeType?.name}</div>
                                    </div>
                                </td>
                                <td>
                                    <div className='fs-6'>
                                        <div className='text-gray-600'>{toMoneyFormat(charge.value)}</div>
                                    </div>
                                </td>
                            </tr>))}

                            <tr>
                                <td></td>
                                <td>
                                    <div>
                                        <div className='fw-bolder mt-5 fs-6'>Total incl Fees</div>
                                        <div className='text-dark-600 fw-bolder fs-5'>{toMoneyFormat(totalCost)}</div>
                                    </div>
                                </td>
                            </tr>

                            {quotes.map((quote) => (<>{quote.attachments &&
                                quote.attachments.length > 0 && (<>
                                    <tr>
                                        <td>
                                            <div className='fs-6'>
                                                <div className='fw-bolder mt-5'>QO-{quote?.quoteNumberId} Attachments
                                                    ({quote.attachments.length}) - see below
                                                </div>
                                                <div>
                                                    {quote.attachments &&
                                                        quote.attachments.map((item: any) => (
                                                            <img
                                                                src={item.uri}
                                                                alt='Quote attachment'
                                                                style={{maxHeight: '540px', maxWidth: '540px'}}
                                                            />
                                                        ))}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </>)}</>))}</tbody>
                        </table>
                    </div>

                    <div className='card-footer'>
                        <div className='card-title fw-bolder'>
                            Notes
                        </div>

                        {quotes.map((quote) => (<div style={{marginBottom: '10px'}}>
                            <div className='fs-6'>
                                <div className='text-gray-600 fw-bolder'>QO-{quote?.quoteNumberId} - Ref
                                    #: {quote?.referenceNumber}</div>
                            </div>

                            <div className='fs-6'>
                                <div className='text-gray-600'>{quote?.description}</div>
                            </div>
                        </div>))}

                    </div>

                    <div className='card-footer'>
                        {quotes.filter(q => q?.quoteStatus?.label !== 'Approved').length > 0 &&
                            quotes.filter(q => q?.quoteStatus?.label !== 'Declined').length > 0 &&
                            quotes.filter(q => q?.quoteStatus?.label !== 'Cancelled').length > 0 && (
                                <div className='card-toolbar' style={{display: 'flex', justifyContent: 'end'}}>
                                    {!invalidQuote && !_.isEmpty(declinedStatus) && (
                                        <button
                                            type='button'
                                            className='btn btn-sm btn-light-primary'
                                            style={{marginRight: '3px'}}
                                            disabled={loading}
                                            onClick={async () => {
                                                setShowDeclineModal(true)
                                            }}
                                        >Decline</button>
                                    )}
                                    {!invalidQuote && !_.isEmpty(approvedStatus) && (
                                        <button
                                            type='button'
                                            className='btn btn-sm btn-primary'
                                            disabled={loading}
                                            onClick={async () => {

                                                setLoading(true)
                                                let unique: any = _.uniq(quotes)
                                                for (let index = 0; index < unique.length; index++) {
                                                    const _quote = unique[index]
                                                    await QuotesClient.Update(
                                                        _quote.id,
                                                        _quote.name,
                                                        _quote.description,
                                                        _quote.referenceNumber,
                                                        _quote.estimate,
                                                        approvedStatus.id
                                                    )
                                                }

                                                setLoading(false)
                                                await fetchData()
                                            }}
                                        >
                                            Approve
                                        </button>
                                    )}
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </>
    )
}
