import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../../app/modules/auth'
import * as workorder from '../../app/modules/work-order/redux/WorkOrderRedux'
import * as checkin from '../../app/modules/check-ins/redux/CheckInsRedux'
import * as site from '../../app/modules/sites/redux/SitesRedux'
import * as user from '../../app/modules/users/redux/UsersRedux'
import * as siteType from '../../app/modules/site-types/redux/SiteTypesRedux'
import * as priority from '../../app/modules/priorities/redux/PrioritiesRedux'
import * as noticeType from '../../app/modules/notice-types/redux/NoticeTypesRedux'
import * as businessType from '../../app/modules/business-types/redux/BusinessTypesRedux'
import * as proposalSections from '../../app/modules/connect/proposal-sections/redux/ProposalSectionsRedux'
import * as proposal from '../../app/modules/connect/proposals/redux/ProposalsRedux'
import * as batchJob from '../../app/modules/batch-jobs/redux/BatchJobsRedux'
import * as leaveSiteReason from '../../app/modules/leave-site-reason/redux/LeaveSiteReasonRedux'
import * as documentType from '../../app/modules/document-types/redux/DocumentTypesRedux'
import * as notice from '../../app/modules/notices/redux/NoticesRedux'
import * as invite from '../../app/modules/invites/redux/InviteRedux'
import * as workOrderStatus from '../../app/modules/work-order-status/redux/WorkOrderStatusRedux'
import * as quoteStatus from '../../app/modules/quote-status/redux/QuoteStatusRedux'
import * as workOrderType from '../../app/modules/work-order-types/redux/WorkOrderTypesRedux'
import * as library from '../../app/modules/library/redux/LibraryRedux'
import * as workScope from '../../app/modules/work-scopes/redux/WorkScopesRedux'
import * as chargeType from '../../app/modules/charge-types/redux/ChargeTypesRedux'
import * as dashboard from '../../app/pages/dashboard/redux/DashboardRedux'
import * as contracts from '../../app/modules/contracts/redux/ContractsRedux'
import * as assetCategories from '../../app/modules/asset-categories/redux/AssetCategoriesRedux'
import * as assets from '../../app/modules/assets/redux/AssetsRedux'
import * as schedules from '../../app/modules/schedules/redux/SchedulesRedux'
import * as contractType from '../../app/modules/contract-types/redux/ContractTypesRedux'
import * as contractCategories from '../../app/modules/contract-categories/redux/ContractCategoriesRedux'
import * as statusFlows from '../../app/modules/status-flows/redux/StatusFlowsRedux'
import * as hazardStatus from '../../app/modules/hazard-status/redux/HazardStatusRedux'
import * as hazards from '../../app/modules/hazards/redux/HazardsRedux'
import * as incidents from '../../app/modules/incidents/redux/IncidentsRedux'
import * as rateCards from '../../app/modules/connect/rate-cards/redux/RateCardsRedux'
import * as rateCardTypes from '../../app/modules/connect/rate-card-types/redux/RateCardTypesRedux'
import * as involvementTypes from '../../app/modules/connect/involvement-types/redux/InvolvementTypesRedux'
import * as exclusions from '../../app/modules/connect/exclusions/redux/ExclusionsRedux'
import * as location from '../../app/modules/locations/redux/LocationsRedux'

export const rootReducer = combineReducers({
    auth: auth.reducer,
    workorder: workorder.reducer,
    checkin: checkin.reducer,
    site: site.reducer,
    siteType: siteType.reducer,
    noticeType: noticeType.reducer,
    priority: priority.reducer,
    workScope: workScope.reducer,
    businessType: businessType.reducer,
    batchJob: batchJob.reducer,
    leaveSiteReason: leaveSiteReason.reducer,
    documentType: documentType.reducer,
    workOrderStatus: workOrderStatus.reducer,
    quoteStatus: quoteStatus.reducer,
    workOrderType: workOrderType.reducer,
    user: user.reducer,
    notice: notice.reducer,
    invite: invite.reducer,
    chargeType: chargeType.reducer,
    library: library.reducer,
    dashboard: dashboard.reducer,
    contracts: contracts.reducer,
    assetCategories: assetCategories.reducer,
    assets: assets.reducer,
    schedules: schedules.reducer,
    contractType: contractType.reducer,
    contractCategories: contractCategories.reducer,
    statusFlows: statusFlows.reducer,
    hazardStatus: hazardStatus.reducer,
    hazards: hazards.reducer,
    incidents: incidents.reducer,
    proposalSections: proposalSections.reducer,
    proposal: proposal.reducer,
    rateCards: rateCards.reducer,
    rateCardTypes: rateCardTypes.reducer,
    involvementTypes: involvementTypes.reducer,
    exclusions: exclusions.reducer,
    location: location.reducer
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
    yield all([
        auth.saga(),
        workorder.saga(),
        checkin.saga(),
        site.saga(),
        siteType.saga(),
        noticeType.saga(),
        businessType.saga(),
        leaveSiteReason.saga(),
        documentType.saga(),
        user.saga(),
        notice.saga(),
        invite.saga(),
        workScope.saga(),
        workOrderStatus.saga(),
        quoteStatus.saga(),
        workOrderType.saga(),
        priority.saga(),
        chargeType.saga(),
        library.saga(),
        dashboard.saga(),
        contracts.saga(),
        assetCategories.saga(),
        assets.saga(),
        schedules.saga(),
        contractType.saga(),
        contractCategories.saga(),
        statusFlows.saga(),
        batchJob.saga(),
        hazardStatus.saga(),
        hazards.saga(),
        incidents.saga(),
        proposalSections.saga(),
        proposal.saga(),
        rateCards.saga(),
        rateCardTypes.saga(),
        involvementTypes.saga(),
        exclusions.saga(),
        location.saga()
    ])
}
